import React, { useContext, useEffect, useState } from 'react';
import Header from './Header';
import axios from 'axios';
import './Dashboard.css';
import { DiamondContext } from '../context/DiamondContext';

const ParameterDetail = ({ diamondData }) => {
  //console.log("from param detail  " ,diamondData);
  return (
    <>
      <table style={{ border: '1px solid black' }}>
        <th>Certificate No</th>
        <th>Stock Id</th>
        <tr>
          <td>{diamondData.Certificate}</td>
          <td>{diamondData.Stock}</td>
        </tr>

        <th>Shade Name</th>
        <th>Milky</th>
        <tr>
          <td>{diamondData.Shade}</td>
          <td>{diamondData.Milky}</td>
        </tr>

        <th>Black Inclusion</th>
        <th>White inclusion</th>
        <tr>
          <td>{diamondData.BlackInclusion}</td>
          <td>-</td>
        </tr>

        <th>Measurement</th>
        <th>Depth / Table</th>
        <tr>
          <td>{diamondData.Measurements}</td>
          <td>{`${diamondData.Depth} / ${diamondData.Table}`}</td>
        </tr>

        <th>Naked Eye</th>
        <th>Size</th>
        <tr>
          <td>EC0</td>
          <td>-</td>
        </tr>

        <th>Classification</th>
        <th>Report Type</th>
        <tr>
          <td>-</td>
          <td>{diamondData.ReportType}</td>
        </tr>
      </table>
    </>
  );
};
const CostInformation = ({ diamondData }) => {
  return (
    <>
      <table style={{ border: '1px solid black' }}>
        <tr>
          <th>Internation Quote:</th>
          <td>{diamondData.Rap}</td>
        </tr>
        <tr>
          <th>Carat:</th>
          <td>{diamondData.Weight}</td>
        </tr>
        <tr>
          <th>Discount:</th>
          <td>{diamondData.RapnetDiscount}</td>
        </tr>
        <tr>
          <th>$/Ct:</th>
          <td>{diamondData.RapNetPrice}</td>
        </tr>
        <tr>
          <th>Purchase USD/piece:</th>
          <td>{diamondData.NetValue}</td>
        </tr>
        <tr>
          <th>RMB/Ct:</th>
          <td>-</td>
        </tr>
        <tr>
          <th>RMB/Piece:</th>
          <td>-</td>
        </tr>
        <tr>
          <th>Exchange rate:</th>
          <td>-</td>
        </tr>
      </table>
    </>
  );
};
const GenerateQuote = ({ diamondData }) => {
  return (
    <>
      <div>International Quote: $4500.00</div>
      <div>Carat: 1.17</div>
      <div>Pre-sale bonus points: +0</div>
      <div>Pre-sale discount: -97.70</div>
      <div>
        <span>Exchange rate: </span>
        <input type="number" />
      </div>
      <div>
        <span>Magnification: </span>
        <input type="number" />
      </div>
      <div>RMB/Cat: 756</div>
      <div>RMB/capsule: 884</div>
      <button type="button" class="btn btn-primary">
        Quote
      </button>
    </>
  );
};


const Dashboard = () => {
  const [expandedId, setExpandedId] = useState(null);
  const [activeSection, setActiveSection] = useState('parameters');
  const [diamondData, setDiamondData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const itemsPerPage = 50;
  const data = useContext(DiamondContext);

  useEffect(() => {
    fetchDiamondsData();
  }, []);

  useEffect(() => {
    updateDataToDisplay();
  }, [diamondData, currentPage]);

  const fetchDiamondsData = async () => {
    setDiamondData(data.diamondData.record);
  };

  const updateDataToDisplay = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    setDataToDisplay(diamondData.slice(startIndex, endIndex));
  };

  const handleExpand = (id) => {
    setExpandedId(expandedId === id ? null : id);
    setActiveSection('parameters'); // Reset to default section when expanded
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  console.log('Data lenght is : ', diamondData.length);
  const totalPages = Math.ceil(diamondData.length / itemsPerPage);

  const renderMobilePagination = () => {
    const visiblePages = Math.min(5, totalPages); // Show max 5 pages initially
    const startIndex = Math.max(
      Math.min(
        currentPage - Math.floor(visiblePages / 2),
        totalPages - visiblePages + 1
      ),
      1
    );
    const endIndex = startIndex + visiblePages - 1;

    const prevButtonDisabled = currentPage === 1;
    const nextButtonDisabled = currentPage === totalPages;

    return (
      <div className="mobile-pagination">
        <button
          disabled={prevButtonDisabled}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          Prev
        </button>
        {Array.from({ length: visiblePages }, (_, index) => (
          <button
            key={index + startIndex}
            className={currentPage === startIndex + index ? 'active' : ''}
            onClick={() => handlePageChange(startIndex + index)}
          >
            {startIndex + index}
          </button>
        ))}
        <button
          disabled={nextButtonDisabled}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          Next
        </button>
      </div>
    );
  };

  return (
    <>
      <div className={{ width: '100%', border: '4px solid red' }}>
        <Header />
        <div className="main">
          <div className="container">
            <div className="header">
              <div className="subhead s1">Check</div>
              <div className="subhead s2">Diamond details</div>
              <div className="subhead s3">
                Discount
                <svg
                  className="sort-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="16"
                  height="16"
                >
                  <path d="M7 10l5-5 5 5H7zM7 14l5 5 5-5H7z" />
                </svg>
              </div>
              <div className="subhead s4">
                RMB/pill
                <svg
                  className="sort-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="16"
                  height="16"
                >
                  <path d="M7 10l5-5 5 5H7zM7 14l5 5 5-5H7z" />
                </svg>
              </div>
            </div>

            {dataToDisplay.map((diamond) => (
              <div key={diamond._id}>
                <div className="listelement">
                  <div className="container-left">
                    <input type="checkbox" />
                  </div>

                  <div
                    className="container-right"
                    onClick={() => handleExpand(diamond._id)}
                  >
                    <div className="container-r-up">
                      <span className="detail">
                        <span>{`${diamond.Weight} `}</span>
                        <span>{`${diamond.Shape} `}</span>

                        <span>{`No color change ${diamond.ReportType}`}</span>
                      </span>

                      <span className="discount">{`${diamond.VendorDiscount.toFixed(
                        2
                      )}`}</span>
                      <span>{`${diamond.Country}`}</span>
                    </div>

                    <div className="container-r-down">
                      <span className="font-weight-bold">
                        {`Spot ${diamond.Color} ${diamond.Clarity} ${diamond.Polish} ${diamond.Symmetry} ${diamond.FluorescenceIntensity}`}
                      </span>

                      <span className="font-weight-bold"> NGTC</span>
                      <span>{` ${diamond.RapNetPrice}`}</span>
                    </div>
                  </div>
                </div>

                {expandedId === diamond._id && (
                  <div className="diamond-desc">
                    <div className="modal-options">
                      <div
                        className={`option ${
                          activeSection === 'parameters' ? 'active' : ''
                        }`}
                        onClick={() => setActiveSection('parameters')}
                      >
                        Parameters details
                      </div>

                      <div
                        className={`option ${
                          activeSection === 'cost' ? 'active' : ''
                        }`}
                        onClick={() => setActiveSection('cost')}
                      >
                        Cost Information
                      </div>
                    </div>

                    <div className="modal-content">
                      {activeSection === 'parameters' && (
                        <ParameterDetail diamondData={diamond} />
                      )}

                      {activeSection === 'cost' && (
                        <CostInformation diamondData={diamond} />
                      )}
                    </div>
                  </div>
                )}
              </div>
            ))}

            <div className="pagination">
              {/* Mobile pagination */}
              {window.innerWidth <= 480 && renderMobilePagination()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
