import React, { Component } from 'react';
import Header from '../Header';
import { Link } from 'react-router-dom'
import axios from 'axios'
import swal from 'sweetalert'

class RequestAPI extends Component {
    constructor(props) {
        super(props);
        this.state = {
            UserName: localStorage.getItem('user'),
            items: '',
            token: localStorage.getItem('token'),
            accepted: ''
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }
    handleSubmit(event) {
        event.preventDefault()


        axios.post('/apiRequest', this.state, {
            headers: {
                'Authorization': `Bearer ${this.state.token}`
            }
        }).then(res => {
            this.setState({
                accepted: res.data.msg
            })
            if (res.data.msg === "ACCEPTED") {
                swal('Your request has already been sent', '', 'warning')
            }
            else {

                swal('REQUEST SENT', '', 'success')

            }
        })
    }



    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        })
    }
    componentDidMount() {

    }
    render() {
        const { UserName } = this.state
        return (
            <div>
                <Header />
                <div className="wrapper">
                    <div className="content-wrapper">

                        <form onSubmit={this.handleSubmit}>

                            {this.state.accepted ? <button type="submit" className="btn btn-info">API REQUEST</button> : <button type="submit" className="btn btn-info" disabled>API REQUEST</button>}



                        </form>
                    </div>
                </div>
            </div>
        );
    }
}


export default RequestAPI;