import { useContext, useEffect, useState } from 'react';
import Header from '../PageComponents/Header';
import AboutUs from '../AboutUs/AboutUs';
import WhyUs from '../WhyUs/WhyUs';
import ContactUs from '../ContactUs/ContactUs';
import Footer from '../PageComponents/Footer';
import useWindowSize from '../../../Hooks/useWindowSize';
import SubWhyChooseUs from './components/SubWhyChooseUs';
import FeaturesAndBenfits from './components/FeaturesAndBenefits';
import ForSuppliers from '../Suppliers/ForSuppliers';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Events from '../Events';
import FAQ from '../FAQ';
import PrivacyPolicy from '../PrivacyPolicy';
import TnC from '../TnC';
import { FaqSectionHome } from '../PageComponents/FaqSectionHome';
import { I18nContext } from '../../../context/i18nContext';

export default function Home() {
  const image1 = '/Illustrations/diamondInventorySystem.png';
  const image2 = '/Illustrations/realTimeInventory.png';
  const image3 = '/Illustrations/instantPurchase.png';
  const image4 = '/Illustrations/mebp.png';
  const image5 = '/Illustrations/roundClock.png';

  const name = window.location.pathname.replace('/', '');
  const [currentPath, setCurrentPath] = useState(name === '' ? 'home' : name);
  const { t } = useContext(I18nContext);

  console.log(window.location.pathname.replace('/', ''));
  const windowSize = useWindowSize();
  const history = useHistory();
  const [isHeaderFixed, setIsHeaderFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 200) {
        setIsHeaderFixed(true);
      } else {
        setIsHeaderFixed(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <main
      style={{
        fontFamily: 'Roboto, serif',
      }}
    >
      <div
        style={{
          minHeight: '100%',
        }}
      >
        <div
          style={{
            height: currentPath === 'home' ? '105vh' : '',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Header
            windowSize={windowSize}
            setCurrentPath={setCurrentPath}
            isFixed={isHeaderFixed}
            currentPath={currentPath}
          />
          {currentPath === 'home' && (
            <div
              style={{
                height: '95vh',
                width: '100%',
                position: 'relative',
              }}
            >
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  top: '0',
                  left: '0',
                  background: 'rgba(0,0,0,0.5)',
                }}
              ></div>
              <video
                autoPlay
                loop
                muted
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
                poster="/assets/homebackground.webp"
              >
                <source
                  src={'/assets/AdobeStock_280467547_Video_HD_Preview.mp4'}
                  type="video/mp4"
                />
                <source
                  src={'/assets/AdobeStock_280467547_Video_HD_Preview.mp4'}
                  type="video/ogg"
                />
              </video>
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  top: '40%',
                }}
              >
                <div
                  style={{
                    // color: '#374151',
                    color: '#FFFFFF',
                    fontSize: '1.125rem',
                    textAlign: 'center',
                    paddingLeft: '1.5rem',
                    paddingRight: '1.5rem',
                  }}
                >
                  {t('instantly')}
                </div>

                <div
                  style={{
                    // color: '#374151',
                    color: '#FFFFFF',
                    fontWeight: 'bold',
                    fontSize: '3rem',
                    marginTop: '0.5rem',
                    textAlign: 'center',
                    padding: '1rem',
                  }}
                >
                  {t('sparkle')}
                </div>
              </div>

              {/* <div style={{ marginTop: '1.25rem', display: 'flex', gap: '1rem' }}>
              <button
                style={{
                  borderRadius: '9999px',
                  padding: '0.5rem 1.25rem',
                  background: '#1D4ED8',
                  color: '#FFFFFF',
                  borderWidth: '0px',
                }}
                onClick={() => history.push('/login')}
              >
                I am a Buyer
              </button>
              <button
                style={{
                  borderRadius: '9999px',
                  padding: '0.5rem 1.25rem',
                  border: '2px solid #1D4ED8',
                  color: '#1D4ED8',
                }}
                onClick={() => history.push('/dashboard')}
              >
                I am a Seller
              </button>
            </div> */}
            </div>
          )}
        </div>

        {currentPath === 'home' && (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddingTop: '2.5rem',
              }}
            >
              <div
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  fontSize: '1.875rem',
                }}
              >
                {t('shineBright')}
              </div>
              <div
                style={{
                  marginTop: '0.8rem',
                  textAlign: 'center',
                  fontSize: '1.25rem',
                  color: '#4B5563',
                }}
              >
                {t('insearch')}
              </div>
              <SubWhyChooseUs
                windowSize={windowSize}
                title={`${t('diamondInTitle')}`}
                text={`${t('diamondInText')}`}
                img={image1}
              />
              <SubWhyChooseUs
                windowSize={windowSize}
                title={`${t('realTimeTitle')}`}
                text={`${t('realTimeText')}`}
                directionRev={true}
                img={image2}
              />
              <SubWhyChooseUs
                windowSize={windowSize}
                title={`${t('instantTitle')}`}
                text={`${t('instantText')}`}
                img={image3}
              />
              <SubWhyChooseUs
                windowSize={windowSize}
                title={`${t('maxEffTitle')}`}
                text={`${t('maxEffText')}`}
                directionRev={true}
                img={image4}
              />
              <SubWhyChooseUs
                windowSize={windowSize}
                title={`${t('roundTitle')}`}
                text={`${t('roundText')}`}
                img={image5}
              />
            </div>

            {/* <div style={{ ...featDisplayResponsive(windowSize) }}>
              <LiveInventory title={'Rahul'} tagLine={'tagline'} />
              <LiveInventory title={'Rahul'} tagLine={'tagline'} />
              <LiveInventory title={'Rahul'} tagLine={'tagline'} />
              <LiveInventory title={'Rahul'} tagLine={'tagline'} />
            </div> */}

            <FeaturesAndBenfits windowSize={windowSize} />

            <div
              style={{
                width: '100%',
                display: 'grid',
                gridTemplateColumns:
                  windowSize < 800 ? 'repeat(1, 1fr)' : 'repeat(2, 1fr)',
                height: windowSize < 800 ? null : '80vh',
              }}
            >
              <div
                style={{
                  background: '#4B5563',
                  backgroundImage: `url('/Illustrations/undraw_Instant_support_re_s7un.png')`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  minHeight: '15rem',
                }}
              ></div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  width: '65%',
                  margin: 'auto',
                }}
              >
                <div style={{ color: '#3B82F6' }}>{t('gotBack')}</div>

                <div
                  style={{
                    marginTop: '0.5rem',
                    fontSize: '2rem',
                    fontWeight: '600',
                  }}
                >
                  {t('customerSup')}
                </div>

                <div style={{ marginTop: '0.75rem', color: '#4B5563' }}>
                  {t('customerSupDesc')}
                </div>

                <button
                  style={{
                    padding: '0.75rem 1.5rem',
                    marginTop: '2.25rem',
                    background: '#1F2937',
                    borderRadius: '0.375rem',
                    fontSize: '1.125rem',
                    color: '#FFFFFF',
                    marginBottom: windowSize < 800 ? '2rem' : 0,
                  }}
                >
                  {t('getStarted')}{' '}
                  {windowSize > 800 && <>{t('shreeDiamond')}</>}
                </button>
              </div>
            </div>
          </>
        )}
        {currentPath === 'aboutUs' && <AboutUs />}
        {currentPath === 'whyUs' && <WhyUs />}
        {currentPath === 'contactUs' && <ContactUs />}
        {currentPath === 'forSuppliers' && <ForSuppliers />}
        {currentPath === 'events' && <Events />}
        {currentPath === 'faq' && <FAQ />}
        {currentPath === 'privacyPolicy' && <PrivacyPolicy />}
        {currentPath === 'tnc' && <TnC />}
      </div>

      {currentPath !== 'forSuppliers' && <FaqSectionHome />}

      <div>
        <Footer windowSize={windowSize} setCurrentPath={setCurrentPath} />
        <div style={{ position: 'fixed', bottom: '2rem', right: '2rem' }}>
          <div
            style={{
              background: '#000',
              color: '#FFFFFF',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '9999px',
              padding: '0.7rem',
              paddingLeft: '1.8rem',
              paddingRight: '1.8rem',
              cursor: 'pointer',
            }}
            onClick={() => {
              if (currentPath.includes('forSuppliers')) {
                window.open(
                  'https://admin.shreediamonds.in/Supplier',
                  '_blank'
                );
              } else history.push('/login');
            }}
          >
            {t('joinNow')}
          </div>
        </div>
      </div>
    </main>
  );
}
