import axios from 'axios';
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import './DisplayImage.css';
import fs from 'fs';
import { request } from 'request';
import { useState } from 'react';
import {
  FacebookShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  TwitterIcon,
  TwitterShareButton,
  FacebookIcon,
  PinterestShareButton,
  PinterestIcon,
  LineIcon,
} from 'react-share';
import useWindowSize from '../../Hooks/useWindowSize';

class DisplayImage extends Component {
  state = {
    loggedIn: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      image: '',
      diamondURL: '',
      diamondVideo: '',
      Inward: [],
      imageCheck: 1,
      videoCheck: 0,
      getCertificate: '',
    };
    this.downloadImage = this.downloadImage.bind(this);
  }
  downloadImage(e) {
    axios
      .get(`/users/downloadImage?URL=${this.state.diamondURL}`)
      .then((resp) => {
        console.log(resp.data);
      });
  }
  componentDidMount() {
    const isLoggedIn = this.checkLoginStatus();

    this.setState({ loggedIn: isLoggedIn });
    const params = new URLSearchParams(window.location.search);
    this.state.image = parseInt(params.get('Certificate'))
      ? parseInt(params.get('Certificate'))
      : params.get('Certificate');
    this.state.getCertificate = params.get('Certificate');

    axios.get(`/users/getDiamondImage/${this.state.image}`).then((res) => {
      this.setState({
        diamondURL: res.data.diamondimage,
        diamondVideo: res.data.diamondVideo,
        Inward: res.data.inward,
      });
      console.log(this.state.diamondURL.toString());
    });
  }
  checkLoginStatus = () => {
    const userToken = localStorage.getItem('token');

    return !!userToken;
  };

  render() {
    const { loggedIn } = this.state;

    const removeBorder = {
      border: 'none',
    };

    return (
      <Fragment>
        <nav class="navbar navbar-expand-lg" style={{ background: 'white' }}>
          <a class="navbar-brand">
            <div style={{ height: 'auto' }}>
              <img
                src="/assets/logo-full.jpg"
                alt="Shree Diamonds"
                height="70px"
              />
            </div>
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavAltMarkup"
            aria-controls="navbarNavAltMarkup"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
            <div class="navbar-nav ml-auto">
              <WhatsappShareButton
                url={`https://shreediamonds.in/video?Certificate=${this.state.getCertificate}`}
              >
                <WhatsappIcon round="true" height="40px"></WhatsappIcon>
              </WhatsappShareButton>
              <TwitterShareButton
                url={`https://shreediamonds.in/video?Certificate=${this.state.getCertificate}`}
                style={{ marginLeft: '-23px' }}
              >
                <TwitterIcon round="true" height="40px"></TwitterIcon>
              </TwitterShareButton>
              <FacebookShareButton
                url={`https://shreediamonds.in/video?Certificate=${this.state.getCertificate}`}
                style={{ marginLeft: '-23px' }}
              >
                <FacebookIcon round="true" height="40px"></FacebookIcon>
              </FacebookShareButton>
              <PinterestShareButton
                media={`https://shreediamonds.in/video?Certificate=${this.state.getCertificate}`}
                url={`https://shreediamonds.in/video?Certificate=${this.state.getCertificate}`}
                style={{ marginLeft: '-23px' }}
              >
                <PinterestIcon round="true" height="40px"></PinterestIcon>
              </PinterestShareButton>
              {!loggedIn && (
                <Link to="/login" className="btn btn-dark">
                  Sign In
                </Link>
              )}
            </div>
          </div>
        </nav>

        <div className="container">
          <div className="box">
            <div className="">
              <div className="" style={{ textAlign: 'center' }}>
                {this.state.diamondVideo == '' &&
                this.state.diamondURL == '' ? (
                  <div
                    style={{
                      backgroundImage:
                        'url(../dist/img/No_Image_Available.png)',
                      backgroundRepeat: 'no-repeat',
                      width: '300px',
                      height: '300px',
                      backgroundSize: 'contain',
                      backgroundPosition: 'center',
                      marginTop: '60px',
                    }}
                  >
                    <img
                      src="assets/diamond.png"
                      alt={this.state.diamondURL}
                      width="300px"
                      style={{
                        mixBlendMode: 'multiply',
                        opacity: '0.5',
                        marginTop: '-20px',
                      }}
                    />
                  </div>
                ) : this.state.diamondVideo != '' &&
                  this.state.diamondURL != '' ? (
                  <div
                    style={{
                      display: window.innerWidth > 1000 ? 'flex' : 'block',
                    }}
                  >
                    <div
                      style={{
                        width: window.innerWidth < 1000 ? '100%' : '50%',
                      }}
                    >
                      <iframe
                        id="iframe1"
                        src={this.state.diamondVideo}
                        style={{
                          height: '100%',
                          minHeight: '300px',
                          width: '80%',
                        }}
                        className="iframe"
                      ></iframe>
                    </div>
                    <div
                      style={{
                        width: window.innerWidth < 1000 ? '100%' : '50%',
                      }}
                    >
                      <img
                        id="iframe1"
                        src={this.state.diamondURL}
                        style={{
                          width: '80%',
                        }}
                      />
                    </div>
                  </div>
                ) : this.state.diamondURL != '' ? (
                  <img
                    id="iframe1"
                    src={this.state.diamondURL}
                    style={{
                      paddingTop: '13%',
                      paddingBottom: '20px',
                      height: '450px',
                      width: '350px',
                    }}
                    alt="NO IMAGE"
                  />
                ) : this.state.diamondVideo ? (
                  <iframe
                    id="iframe1"
                    src={this.state.diamondVideo}
                    style={{ paddingTop: '13%' }}
                    height="550"
                    width="350"
                    className="iframe"
                  ></iframe>
                ) : (
                  <div
                    style={{
                      backgroundImage: 'url(/assets/diamond.png)',
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'contain',
                      backgroundPosition: 'center',
                      width: '300',
                      mixBlendMode: '',
                    }}
                  >
                    <img
                      src="../dist/img/No_Image_Available.png"
                      width="300"
                      style={{ marginTop: '100px' }}
                      alt={this.state.diamondURL}
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-8">
              <div style={{ paddingTop: '48px' }}>
                <table className="zui-table table v_table ">
                  {this.state.Inward.map((view) => (
                    <Fragment>
                      <div className="row">
                        <div className="col-md-6">
                          <table>
                            <tbody>
                              <tr className="text-center">
                                <th style={removeBorder}>Shape</th>
                                <td style={removeBorder}>{view.Shape}</td>
                              </tr>
                              <tr className="text-center tableD">
                                <th style={removeBorder}>Weight</th>
                                <td style={removeBorder}>{view.Weight}</td>
                              </tr>
                              <tr className="text-center">
                                <th style={removeBorder}>Clarity</th>
                                <td style={removeBorder}>{view.Clarity}</td>
                              </tr>
                              <tr className="text-center">
                                <th style={removeBorder}>Color</th>
                                <td style={removeBorder}>{view.Color}</td>
                              </tr>
                              <tr className="text-center">
                                <th style={removeBorder}>Cut Grade</th>
                                <td style={removeBorder}>{view.CutGrade}</td>
                              </tr>
                              <tr className="text-center">
                                <th style={removeBorder}>Polish</th>
                                <td style={removeBorder}>{view.Polish}</td>
                              </tr>
                              <tr className="text-center">
                                <th style={removeBorder}>Symmentry</th>
                                <td style={removeBorder}>{view.Symmetry}</td>
                              </tr>
                              <tr className="text-center">
                                <th style={removeBorder}>Flu.Color</th>
                                <td style={removeBorder}>
                                  {view.FluresenceColor
                                    ? view.FluresenceColor
                                    : '-'}
                                </td>
                              </tr>
                              <tr className="text-center">
                                <th style={removeBorder}>Lab</th>
                                <td style={removeBorder}>{view.Lab}</td>
                              </tr>
                              <tr className="text-center">
                                <th style={removeBorder}>Certificate No.</th>
                                <td style={removeBorder}>{view.Certificate}</td>
                              </tr>
                              <tr className="text-center">
                                <th style={removeBorder}>Measurements</th>
                                <td style={removeBorder}>
                                  {view.Measurements}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="col-md-6">
                          <table>
                            <tbody>
                              <tr className="text-center">
                                <th style={removeBorder}>Crown Angle</th>
                                <td style={removeBorder}>{view.CrownAngle}</td>
                              </tr>
                              <tr className="text-center">
                                <th style={removeBorder}>Crown Height</th>
                                <td style={removeBorder}>{view.CrownHeight}</td>
                              </tr>
                              <tr className="text-center">
                                <th style={removeBorder}>Pavilion Angle</th>
                                <td style={removeBorder}>
                                  {view.PavilionAngle}
                                </td>
                              </tr>
                              <tr className="text-center">
                                <th style={removeBorder}>Pavilion Depth</th>
                                <td style={removeBorder}>
                                  {view.PavilionDepth}
                                </td>
                              </tr>
                              <tr className="text-center">
                                <th style={removeBorder}>Table</th>
                                <td style={removeBorder}>{view.Table}</td>
                              </tr>
                              <tr className="text-center">
                                <th style={removeBorder}>Depth</th>
                                <td style={removeBorder}>{view.Depth}</td>
                              </tr>
                              <tr className="text-center">
                                <th style={removeBorder}>Girdle</th>
                                <td style={removeBorder}>{view.Girdle}</td>
                              </tr>
                              <tr className="text-center">
                                <th style={removeBorder}>Ratio</th>
                                <td style={removeBorder}>{view.Ratio}</td>
                              </tr>
                              <tr className="text-center">
                                <th style={removeBorder}>Black Inclusion</th>
                                <td style={removeBorder}>
                                  {view.BlackInclusion}
                                </td>
                              </tr>
                              <tr className="text-center">
                                <th style={removeBorder}>Shade</th>
                                <td style={removeBorder}>{view.Shade}</td>
                              </tr>
                              <tr className="text-center">
                                <th style={removeBorder}>Milky</th>
                                <td style={removeBorder}>{view.Milky}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </Fragment>
                  ))}
                </table>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default DisplayImage;
