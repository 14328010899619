import Axios from 'axios';
import { token } from 'morgan';
import React, { Component, Fragment } from 'react';
import Header from '../Header';
import './SearchDiamond.css';
import $, { data } from 'jquery';

import { search } from './utils';
import { DiamondContext } from '../../context/DiamondContext.js';

import AnimatedNumber from 'animated-number-react';
import _, { debounce, filter } from 'lodash';
import { DebounceInput } from 'react-debounce-input';
import axios from 'axios';
import Footer from '../Footer';
import { I18nContext } from '../../context/i18nContext.js';
import { t } from 'i18next';

class SearchDiamond extends Component {

  static contextType = DiamondContext;

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      ShowDiamonds: [],
      data2: [],
      loading: '',
      loader: '',
      count: '',
      countNumber: '',
      UserID: '',
      suffix: '',
      filter: {
        Shape: [],
        Color: [],
        Clarity: [],
        Polish: [],
        CutGrade: [],
        Symmetry: [],
        FluorescenceIntensity: [],
        Lab: [],
        Country: [],
        Stock: [],
        Certificate: [],
        Weight: [],
        Milky: [],
        Shade: [],
        NetValue: [],
        VendorDiscount: [],
        Depth: [],
        Table: [],
        PavilionAngle: [],
        CrownAngle: [],
        Girdle: [],
        Ratio: [],
        RapNetPrice: [],
        CrownHeight: [],
        BlackInclusion: [],
        HandA: [],
        EC: [],
        FancyColor: [],
        FancyColorIntensity: [],
        FancyColorOvertone: [],
      },
      filterID: {
        Shape: [],
        Color: [],
        Clarity: [],
        Polish: [],
        CutGrade: [],
        Symmetry: [],
        FluorescenceIntensity: [],
        Lab: [],
        Country: [],
        Stock: [],
        Certificate: [],
        Weight: [],
        Milky: [],
        Shade: [],
        NetValue: [],
        VendorDiscount: [],
        Depth: [],
        Table: [],
        PavilionAngle: [],
        CrownAngle: [],
        Girdle: [],
        Ratio: [],
        RapNetPrice: [],
        CrownHeight: [],
        BlackInclusion: [],
        HandA: [],
        EC: [],
        FancyColor: [],
        FancyColorIntensity: [],
        FancyColorOvertone: [],
      },
      total: 0,
      avg: 0,
      filterID1: {},
      filterSaved: {},
      Stock: '',
      Certificate: '',
      Weight1: '',
      Weight2: '',
      netValue1: '',
      netValue2: '',
      VendorDiscount1: '',
      VendorDiscount2: '',
      Depth1: '',
      Depth2: '',
      Table1: '',
      Table2: '',
      PavilionAngle1: '',
      PavilionAngle2: '',
      CrownAngle1: '',
      CrownAngle2: '',
      Girdle1: '',
      Girdle2: '',
      Ratio1: '',
      Ratio2: '',
      PricePerCarat1: '',
      PricePerCarat2: '',
      CrownHeight1: '',
      CrownHeight2: '',
      record: [],
      FancyColor1: [],
      FancyColorIntensity1: [],
      FancyColorOvertone1: [],
      Type: '',
      getType: '',
      getTypeID: '',
      btnClick: false,
      totalWeight: 0,
      totalPrice: 0,
      avg1: 0,
      RapDisc: 0,
    };
    this.handleChage = this.handleChage.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.resetData = this.resetData.bind(this);
    this.handleChangeStock = this.handleChangeStock.bind(this);
    this.handleCertificateChange = this.handleCertificateChange.bind(this);
    this.handleWeightChange = this.handleWeightChange.bind(this);
    this.handlePriceChange = this.handlePriceChange.bind(this);
    this.handleVendorDiscountChange =
      this.handleVendorDiscountChange.bind(this);
    this.handleDepthChange = this.handleDepthChange.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handlePavilionAngleChange = this.handlePavilionAngleChange.bind(this);
    this.handleCrownAngleChange = this.handleCrownAngleChange.bind(this);
    this.handleGirdleChange = this.handleGirdleChange.bind(this);
    this.handleRatioChange = this.handleRatioChange.bind(this);
    this.handlePricePerCaratChange = this.handlePricePerCaratChange.bind(this);
    this.handleCrownHeightChange = this.handleCrownHeightChange.bind(this);
    this.handleEX = this.handleEX.bind(this);
    this.handleVG = this.handleVG.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleGetID = this.handleGetID.bind(this);
    //   this.saveSearch = this.saveSearch.bind(this)
  }
  handleGetID(e) {
    // console.log(e.target.id)
  }
  handleTypeChange(e) {
    if (!this.state.filter) {
      this.setState({
        countNumber: 0,
      });
    }

    // this.setState({ loader: true })
    this.state.Type = e.target.id;
    //console.log(this.state.Type);
    this.countData();
  }
  handleCrownHeightChange(e) {
    var trid = $('#' + e.target.id)
      .closest('tr')
      .attr('id');
    if (trid == 'CrownHeight1') {
      this.state.CrownHeight1 = e.target.value;
      var crownheight1 = e.target.id;
      this.state.filter.CrownHeight.splice(0, 1, this.state.CrownHeight1);
      this.state.filterID.CrownHeight.splice(0, 1, crownheight1);
    } else if (trid == 'CrownHeight2') {
      this.state.CrownHeight2 = e.target.value;
      var crownheight2 = e.target.id;
      this.state.filter.CrownHeight.splice(1, 1, this.state.CrownHeight2);
      this.state.filterID.CrownHeight.splice(1, 1, crownheight2);
    }
    if (
      this.state.filter.CrownHeight[0] == '' &&
      this.state.filter.CrownHeight[1] == ''
    ) {
      this.state.filter.CrownHeight = [];
      this.state.filterID.CrownHeight = [];
    }
    this.countData();
    //this.search(JSON.stringify(this.state.filter))
  }
  handlePricePerCaratChange(e) {
    var trid = $('#' + e.target.id)
      .closest('tr')
      .attr('id');
    if (trid == 'PricePerCarat1') {
      this.state.PricePerCarat1 = e.target.value;
      var ppc1 = e.target.id;
      this.state.filter.RapNetPrice.splice(0, 1, this.state.PricePerCarat1);
      this.state.filterID.RapNetPrice.splice(0, 1, ppc1);
    } else if (trid == 'PricePerCarat2') {
      this.state.PricePerCarat2 = e.target.value;
      var ppc2 = e.target.id;
      this.state.filter.RapNetPrice.splice(1, 1, this.state.PricePerCarat2);
      this.state.filterID.RapNetPrice.splice(1, 1, ppc2);
    }
    if (
      this.state.filter.RapNetPrice[0] == '' &&
      this.state.filter.RapNetPrice[1] == ''
    ) {
      this.state.filter.RapNetPrice = [];
      this.state.filterID.RapNetPrice = [];
    }
    this.countData();
    //this.search(JSON.stringify(this.state.filter))
  }
  handleRatioChange(e) {
    var trid = $('#' + e.target.id)
      .closest('tr')
      .attr('id');

    if (trid == 'Ratio1') {
      this.state.Ratio1 = e.target.value;

      this.state.filter.Ratio[0] = parseFloat(this.state.Ratio1);
    } else if (trid == 'Ratio2') {
      this.state.Ratio2 = e.target.value;

      this.state.filter.Ratio[1] = parseFloat(this.state.Ratio2);
    }
    this.countData();
  }
  handleGirdleChange(e) {
    var trid = $('#' + e.target.id)
      .closest('tr')
      .attr('id');

    if (trid == 'Girdle1') {
      this.state.Girdle1 = e.target.value;
      var girdle1 = e.target.id;
      this.state.filter.Girdle.splice(0, 1, this.state.Girdle1);
      this.state.filterID.Girdle.splice(0, 1, girdle1);
    } else if (trid == 'Girdle2') {
      this.state.Girdle2 = e.target.value;
      var girdle2 = e.target.id;
      this.state.filter.Girdle.splice(1, 1, this.state.Girdle2);
      this.state.filterID.Girdle.splice(1, 1, girdle2);
    }
    if (
      this.state.filter.Girdle[0] == '' &&
      this.state.filter.Girdle[1] == ''
    ) {
      this.state.filter.Girdle = [];
      this.state.filterID.Girdle = [];
    }
    this.countData();
    //this.search(JSON.stringify(this.state.filter))
  }
  handleCrownAngleChange(e) {
    var trid = $('#' + e.target.id)
      .closest('tr')
      .attr('id');
    if (trid == 'CrownAngle1') {
      this.state.CrownAngle1 = e.target.value;
      var cangle1 = e.target.id;
      this.state.filter.CrownAngle.splice(0, 1, this.state.CrownAngle1);
      this.state.filterID.CrownAngle.splice(0, 1, cangle1);
    } else if (trid == 'CrownAngle2') {
      this.state.CrownAngle2 = e.target.value;
      var cangle2 = e.target.id;
      this.state.filter.CrownAngle.splice(1, 1, this.state.CrownAngle2);
      this.state.filterID.CrownAngle.splice(1, 1, cangle2);
    }

    if (
      this.state.filter.CrownAngle[0] == '' &&
      this.state.filter.CrownAngle[1] == ''
    ) {
      this.state.filter.CrownAngle = [];
      this.state.filterID.CrownAngle = [];
    }
    this.countData();
    //this.search(JSON.stringify(this.state.filter))
  }
  handlePavilionAngleChange(e) {
    var trid = $('#' + e.target.id)
      .closest('tr')
      .attr('id');
    if (trid == 'PavilionAngle1') {
      this.state.PavilionAngle1 = e.target.value;
      var pavAng1 = e.target.id;
      this.state.filter.PavilionAngle.splice(0, 1, this.state.PavilionAngle1);
      this.state.filterID.PavilionAngle.splice(0, 1, pavAng1);
    } else if (trid == 'PavilionAngle2') {
      this.state.PavilionAngle2 = e.target.value;
      var pavAng2 = e.target.id;
      this.state.filter.PavilionAngle.splice(1, 1, this.state.PavilionAngle2);
      this.state.filterID.PavilionAngle.splice(1, 1, pavAng2);
    }
    if (
      this.state.filter.PavilionAngle[0] == '' &&
      this.state.filter.PavilionAngle[1] == ''
    ) {
      this.state.filter.PavilionAngle = [];
      this.state.filterID.PavilionAngle = [];
    }
    this.countData();
    //this.search(JSON.stringify(this.state.filter))
  }
  handleTableChange(e) {
    var trid = $('#' + e.target.id)
      .closest('tr')
      .attr('id');

    if (trid == 'Table1') {
      this.state.Table1 = e.target.value;
      var table1 = e.target.id;
      this.state.filter.Table.splice(0, 1, this.state.Table1);
      this.state.filterID.Table.splice(0, 1, table1);
    } else if (trid == 'Table2') {
      this.state.Table2 = e.target.value;
      var table2 = e.target.id;
      this.state.filter.Table.splice(1, 1, this.state.Table2);
      this.state.filterID.Table.splice(1, 1, table2);
    }
    if (this.state.filter.Table[0] == '' && this.state.filter.Table[1] == '') {
      this.state.filter.Table = [];
      this.state.filterID.Table = [];
    }
    this.countData();
    //this.search(JSON.stringify(this.state.filter))
  }
  handleDepthChange(e) {
    var trid = $('#' + e.target.id)
      .closest('tr')
      .attr('id');
    if (trid == 'Depth1') {
      this.state.Depth1 = e.target.value;
      var depth1 = e.target.id;
      this.state.filter.Depth.splice(0, 1, this.state.Depth1);
      this.state.filterID.Depth.splice(0, 1, depth1);
    } else if (trid == 'Depth2') {
      this.state.Depth2 = e.target.value;
      var depth2 = e.target.id;
      this.state.filter.Depth.splice(1, 1, this.state.Depth2);
      this.state.filterID.Depth.splice(1, 1, depth2);
    }
    if (this.state.filter.Depth[0] == '' && this.state.filter.Depth[1] == '') {
      this.state.filter.Depth = [];
      this.state.filterID.Depth = [];
    }
    this.countData();
    //this.search(JSON.stringify(this.state.filter))
  }
  handleVendorDiscountChange(e) {
    var trid = $('#' + e.target.id)
      .closest('tr')
      .attr('id');
    if (trid == 'VendorDiscount1') {
      this.state.VendorDiscount1 = e.target.value;
      var VendorDiscount1 = e.target.id;
      this.state.filter.VendorDiscount.splice(0, 1, this.state.VendorDiscount1);
      this.state.filterID.VendorDiscount.splice(0, 1, VendorDiscount1);
    } else if (trid == 'VendorDiscount2') {
      this.state.VendorDiscount2 = e.target.value;
      var VendorDiscount2 = e.target.id;
      this.state.filter.VendorDiscount.splice(1, 1, this.state.VendorDiscount2);
      this.state.filterID.VendorDiscount.splice(1, 1, VendorDiscount2);
    }
    if (
      this.state.filter.VendorDiscount[0] == '' &&
      this.state.filter.VendorDiscount[1] == ''
    ) {
      this.state.filter.VendorDiscount = [];
      this.state.filterID.VendorDiscount = [];
    }
    this.countData();
    //this.search(JSON.stringify(this.state.filter))
  }
  handlePriceChange(e) {
    var trid = $('#' + e.target.id)
      .closest('tr')
      .attr('id');
    if (trid == 'netValue1') {
      this.state.netValue1 = e.target.value;
      var NetValue1 = e.target.id;
      this.state.filter.NetValue.splice(0, 1, this.state.netValue1);
      this.state.filterID.NetValue.splice(0, 1, NetValue1);
    } else if (trid == 'netValue2') {
      this.state.netValue2 = e.target.value;
      var NetValue2 = e.target.id;
      this.state.filter.NetValue.splice(1, 1, this.state.netValue2);
      this.state.filterID.NetValue.splice(1, 1, NetValue2);
    }

    if (
      this.state.filter.NetValue[0] == '' &&
      this.state.filter.NetValue[1] == ''
    ) {
      this.state.filter.NetValue = [];
      this.state.filterID.NetValue = [];
    }
    this.countData();
    //this.search(JSON.stringify(this.state.filter))
  }
  handleWeightChange(e) {
    var trid = $('#' + e.target.id)
      .closest('tr')
      .attr('id');

    if (trid == 'weight1') {
      this.state.Weight1 = e.target.value;
      var WeightID1 = e.target.id;
      this.state.filter.Weight.splice(0, 1, this.state.Weight1);
      this.state.filterID.Weight.splice(0, 1, WeightID1);
    } else if (trid == 'weight2') {
      this.state.Weight2 = e.target.value;
      var WeightID2 = e.target.id;
      this.state.filter.Weight.splice(1, 1, this.state.Weight2);
      this.state.filterID.Weight.splice(1, 1, WeightID2);
    }

    if (
      this.state.filter.Weight[0] == '' &&
      this.state.filter.Weight[1] == ''
    ) {
      this.state.filter.Weight = [];
      this.state.filterID.Weight = [];
    }
    this.countData();
    //this.search(JSON.stringify(this.state.filter))
  }
  handleCertificateChange(e) {
    this.setState({
      Certificate: e.target.value,
    });
    var CertiID = e.target.id;
    var getChar = this.state.Certificate.split(',');
    for (var i = 0; i < getChar.length; i++) {
      var getInitial = getChar[i].substring(0, 2).toUpperCase();

      if (getInitial == 'SD') {
        this.state.filter.Stock = getChar[i].split(',');
        this.state.filterID.Stock = CertiID;
      } else {
        this.state.filter.Certificate = getChar[i].split(',');
        this.state.filterID.Certificate = CertiID;
      }
    }
    this.countData();
    //this.search(JSON.stringify(this.state.filter))
  }
  handleChangeStock(e) {
    this.setState({
      Stock: e.target.value,
    });
    this.state.filter.Stock = this.state.Stock.split(',');
    this.countData();
    //this.search(JSON.stringify(this.state.filter))
  }
  resetData(e) {
    Axios.get(`/users/deleteCustFilter/${localStorage.getItem('ID')}`).then(
      (res) => {
        $('label').css({ background: '', color: '' });
        $('input:checkbox').prop('checked', false);
        $('select').prop('selectedIndex', 0);
        $('input').val('');
        this.setState({
          filter: {
            Shape: [],
            Color: [],
            Clarity: [],
            Polish: [],
            CutGrade: [],
            Symmetry: [],
            FluorescenceIntensity: [],
            Lab: [],
            Country: [],
            Stock: [],
            Certificate: [],
            Weight: [],
            Milky: [],
            Shade: [],
            NetValue: [],
            VendorDiscount: [],
            Depth: [],
            Table: [],
            PavilionAngle: [],
            CrownAngle: [],
            Girdle: [],
            Ratio: [],
            RapNetPrice: [],
            CrownHeight: [],
            BlackInclusion: [],
            HandA: [],
            EC: [],
            FancyColor: [],
            FancyColorIntensity: [],
            FancyColorOvertone: [],
          },
          filterID: {
            Shape: [],
            Color: [],
            Clarity: [],
            Polish: [],
            CutGrade: [],
            Symmetry: [],
            FluorescenceIntensity: [],
            Lab: [],
            Country: [],
            Stock: [],
            Certificate: [],
            Weight: [],
            Milky: [],
            Shade: [],
            NetValue: [],
            VendorDiscount: [],
            Depth: [],
            Table: [],
            PavilionAngle: [],
            CrownAngle: [],
            Girdle: [],
            Ratio: [],
            RapNetPrice: [],
            CrownHeight: [],
            BlackInclusion: [],
            HandA: [],
            EC: [],
            FancyColor: [],
            FancyColorIntensity: [],
            FancyColorOvertone: [],
          },
        });
      }
    );
    //this.componentDidMount();
  }
  // search = debounce(async val => {
  //     this.setState({ loading: true, loader: true });
  //     // const res = await search(
  //     //     `/users/getCount/${val}`
  //     // );
  //     // const inwards = res.result;
  //     // // const total = res.total;
  //     // // const avg = res.avg
  //     axios.get(`/users/getCount/${val}`).then(resp => {
  //         this.setState({
  //             loading: false,
  //             countNumber: resp.data.count,
  //             loader: false,
  //         })
  //     })
  //     // this.setState({ inwards, loading: false, countNumber: inwards, loader: false });
  // }, 1000);
  handleChage(e) {
    var trid = $('#' + e.target.id)
      .closest('tr')
      .attr('id');
    if (e.target.checked) {
      $('#' + e.target.id).css('background-color', '#17a2b8');
      $('#' + e.target.id).css('color', '#fff');
    } else {
      $('#' + e.target.id).css('background-color', '#fff');
      $('#' + e.target.id).css('color', '#000');
    }
    if (trid == 'shape') {
      if (e.target.checked) {
        this.state.filter.Shape.push(e.target.value);
        this.state.filterID.Shape.push(e.target.id);
      } else {
        var shape = this.state.filter.Shape.indexOf(e.target.value);
        var shapeID = this.state.filterID.Shape.indexOf(e.target.id);
        this.state.filter.Shape.splice(shape, 1);
        this.state.filterID.Shape.splice(shapeID, 1);
      }
    }
    if (trid == 'color2') {
      if (e.target.checked) {
        this.state.filter.Color.push(e.target.value);
        this.state.filterID.Color.push(e.target.id);
      } else {
        var color = this.state.filter.Color.indexOf(e.target.value);
        var colorID = this.state.filterID.Color.indexOf(e.target.id);
        this.state.filter.Color.splice(color, 1);
        this.state.filterID.Color.splice(colorID, 1);
      }
    }
    if (trid == 'clarity') {
      if (e.target.checked) {
        this.state.filter.Clarity.push(e.target.value);
        this.state.filterID.Clarity.push(e.target.id);
      } else {
        var clarity = this.state.filter.Clarity.indexOf(e.target.value);
        var clarityID = this.state.filterID.Clarity.indexOf(e.target.id);
        this.state.filter.Clarity.splice(clarity, 1);
        this.state.filterID.Clarity.splice(clarityID, 1);
      }
    }
    if (trid == 'cut') {
      if (e.target.checked) {
        this.state.filter.CutGrade.push(e.target.value);
        this.state.filterID.CutGrade.push(e.target.id);
      } else {
        var cut = this.state.filter.CutGrade.indexOf(e.target.value);
        var cutID = this.state.filterID.CutGrade.indexOf(e.target.id);
        this.state.filter.CutGrade.splice(cut, 1);
        this.state.filterID.CutGrade.splice(cutID, 1);
      }
    }
    if (trid == 'polish') {
      if (e.target.checked) {
        this.state.filter.Polish.push(e.target.value);
        this.state.filterID.Polish.push(e.target.id);
      } else {
        var polish = this.state.filter.Polish.indexOf(e.target.value);
        var polishID = this.state.filterID.Polish.indexOf(e.target.id);
        this.state.filter.Polish.splice(polish, 1);
        this.state.filterID.Polish.splice(polishID, 1);
      }
    }
    if (trid == 'SYMM') {
      if (e.target.checked) {
        this.state.filter.Symmetry.push(e.target.value);
        this.state.filterID.Symmetry.push(e.target.id);
      } else {
        var sym = this.state.filter.Symmetry.indexOf(e.target.value);
        var symID = this.state.filterID.Symmetry.indexOf(e.target.id);
        this.state.filter.Symmetry.splice(sym, 1);
        this.state.filterID.Symmetry.splice(symID, 1);
      }
    }
    if (trid == 'flour') {
      if (e.target.checked) {
        this.state.filter.FluorescenceIntensity.push(e.target.value);
        this.state.filterID.FluorescenceIntensity.push(e.target.id);
      } else {
        var flu = this.state.filter.FluorescenceIntensity.indexOf(
          e.target.value
        );
        var fluID = this.state.filterID.FluorescenceIntensity.indexOf(
          e.target.id
        );
        this.state.filter.FluorescenceIntensity.splice(flu, 1);
        this.state.filterID.FluorescenceIntensity.splice(fluID, 1);
      }
    }
    if (trid == 'cert') {
      if (e.target.checked) {
        this.state.filter.Lab.push(e.target.value);
        this.state.filterID.Lab.push(e.target.id);
      } else {
        var lab = this.state.filter.Lab.indexOf(e.target.value);
        var labID = this.state.filterID.Lab.indexOf(e.target.id);
        this.state.filter.Lab.splice(lab, 1);
        this.state.filterID.Lab.splice(labID, 1);
      }
    }
    if (trid == 'Country') {
      if (e.target.checked) {
        this.state.filter.Country.push(e.target.value);
        this.state.filterID.Country.push(e.target.id);
      } else {
        var country = this.state.filter.Country.indexOf(e.target.value);
        var countryID = this.state.filterID.Country.indexOf(e.target.id);
        this.state.filter.Country.splice(country, 1);
        this.state.filterID.Country.splice(countryID, 1);
      }
    }
    if (trid == 'Milky') {
      if (e.target.checked) {
        this.state.filter.Milky.push(e.target.value);
        this.state.filterID.Milky.push(e.target.id);
      } else {
        var milky = this.state.filter.Milky.indexOf(e.target.value);
        var milkyID = this.state.filterID.Milky.indexOf(e.target.id);
        this.state.filter.Milky.splice(milky, 1);
        this.state.filterID.Milky.splice(milkyID, 1);
      }
    }
    if (trid == 'SHADE') {
      if (e.target.checked) {
        this.state.filter.Shade.push(e.target.value);
        this.state.filterID.Shade.push(e.target.id);
      } else {
        var shade = this.state.filter.Shade.indexOf(e.target.value);
        var shadeID = this.state.filterID.Shade.indexOf(e.target.id);
        this.state.filter.Shade.splice(shade, 1);
        this.state.filterID.Shade.splice(shadeID, 1);
      }
    }
    if (trid == 'BlackInclusion') {
      if (e.target.checked) {
        this.state.filter.BlackInclusion.push(e.target.value);
        this.state.filterID.BlackInclusion.push(e.target.id);
      } else {
        var BlackInclusion = this.state.filter.BlackInclusion.indexOf(
          e.target.value
        );
        var BlackInclusionID = this.state.filterID.BlackInclusion.indexOf(
          e.target.id
        );
        this.state.filter.BlackInclusion.splice(BlackInclusion, 1);
        this.state.filterID.BlackInclusion.splice(BlackInclusionID, 1);
      }
    }
    if (trid == 'HandA') {
      if (e.target.checked) {
        this.state.filter.HandA.push(e.target.value);
        this.state.filterID.HandA.push(e.target.id);
      } else {
        var HandA = this.state.filter.HandA.indexOf(e.target.value);
        var HandAID = this.state.filterID.HandA.indexOf(e.target.id);
        this.state.filter.HandA.splice(HandA, 1);
        this.state.filterID.HandA.splice(HandAID, 1);
      }
    }
    if (trid == 'EC') {
      if (e.target.checked) {
        this.state.filter.EC.push(e.target.value);
        this.state.filterID.EC.push(e.target.id);
      } else {
        var EC = this.state.filter.EC.indexOf(e.target.value);
        var ECID = this.state.filterID.EC.indexOf(e.target.id);
        this.state.filter.EC.splice(EC, 1);
        this.state.filterID.EC.splice(ECID, 1);
      }
    }
    if (trid == 'fancycolor') {
      if (e.target.value) {
        this.state.filter.FancyColor = [];
        this.state.filterID.FancyColor = [];
        this.state.filter.FancyColor.push(e.target.value);
        this.state.filterID.FancyColor.push(e.target.id);
      }
    }
    if (trid == 'fancycolorintensity') {
      if (e.target.value) {
        this.state.filter.FancyColorIntensity = [];
        this.state.filterID.FancyColorIntensity = [];
        this.state.filter.FancyColorIntensity.push(e.target.value);
        this.state.filterID.FancyColorIntensity.push(e.target.id);
      }
    }
    if (trid == 'fancycolorovertone') {
      if (e.target.value) {
        this.state.filter.FancyColorOvertone = [];
        this.state.filterID.FancyColorOvertone = [];
        this.state.filter.FancyColorOvertone.push(e.target.value);
        this.state.filterID.FancyColorOvertone.push(e.target.id);
      }
    }
    this.countData();

    // this.search(JSON.stringify(this.state.filter))
  }
  countData = debounce((async) => {});
  checkProperties(obj) {
    for (var key in obj) {
      if (obj[key] !== null && obj[key] != '') return false;
    }
    return true;
  }
  handleClick(e) {
    e.preventDefault();
    if (this.checkProperties(this.state.filter)) {
      alert('Please select parameters');
    } else {
      this.setState({
        btnClick: true,
        loader: true,
      });
      axios
        .get('/users/getDiamonds', {
          params: {
            type: this.state.Type,
            filters: this.state.filter,
          },
        })
        .then((resp) => {
          if (resp.data.error) {
            this.setState({
              loader: false,
              btnClick: false,
            });
            return alert(resp.data.error);
          }
          this.setState({
            record: resp.data.filtered,
            totalWeight: resp.data.totalWeight,
            totalPrice: resp.data.totalPrice,
            avg1: resp.data.avg,
            RapDisc: resp.data.RapDiscount,
          });
          Axios.post(`/users/saveSearch/${this.state.Type}`, {
            UserID: this.state.UserID,
            filter: this.state.filter,
            filterID: this.state.filterID,
          }).then((res) => {
            if (
              this.state.record == '' ||
              this.state.record == [] ||
              this.state.record == null
            ) {
              alert('No records found!!');
              this.setState({
                loader: false,
                btnClick: false,
              });
            } else {
              if (window.innerWidth <= 480) {
                const { setDiamondData } = this.context;
                const diamondData = {
                  record: this.state.record,
                  filtered: this.state.filter,
                  totalWeight: this.state.totalWeight,
                  totalPrice: this.state.totalPrice,
                  avg1: this.state.avg1,
                  RapDisc: this.state.RapDisc,
                  count: this.state.record.length,
                };
              
                // Update context state
                setDiamondData((prev) => ({
                  ...prev,
                  ...diamondData, // Spread diamondData directly
                }));
              
                // Log the updated context state (optional)
                //console.log("Updated Context Data:", this.context);
                //console.log("Updated state Data:", this.state);
              
                // Navigate to '/diamondList'
                this.props.history.push({
                  pathname: '/diamondList',
                });
              }
               else {
                //const pathname = window.innerWidth <= 480 ? '/diamondList' : '/viewInwardCustomer';
                this.props.history.push({
                  pathname: '/viewInwardCustomer',
                  record: this.state.record,
                  count: this.state.record.length,
                  filtered: this.state.filter,
                  totalWeight: this.state.totalWeight,
                  totalPrice: this.state.totalPrice,
                  avg1: this.state.avg1,
                  RapDisc: this.state.RapDisc,
                });
              }
            }
          });
        });

      // this.countData()
      // axios.get(`/users/getDiamonds?filters=${this.state.filter}`).then(resp => {
      //     console.log(resp)
      // })
      // if (this.state.record == null || this.state.record == "" || this.state.record == []) {
      //     alert('No data available')
      // }
      // else {
      //     console.log(this.state.record)
      //     Axios.post(`/users/saveSearch/${this.state.Type}`, this.state).then(res => {
      //         this.props.history.push({
      //             pathname: '/viewInwardCustomer',
      //             record: this.state.record,
      //             count: this.state.countNumber,
      //             filtered: this.state.filter,
      //         })

      //     })
      // }
    }
    console.log("The state on submit is: ", this.state)
  }
  buildFilter = (filter) => {
    let query = {};
    for (let keys in filter) {
      if (filter[keys].constructor === Array && filter[keys].length > 0) {
        query[keys] = filter[keys];
      }
    }
    return query;
  };
  filterArray(array, filters) {
    const filterKeys = Object.keys(filters);
    return array.filter((item) => {
      // validates all filter criteria
      return filterKeys.every((key) => {
        if (
          key == 'Weight' ||
          key == 'NetValue' ||
          key == 'VendorDiscount' ||
          key == 'RapNetPrice' ||
          key == 'Depth' ||
          key == 'Table' ||
          key == 'Girdele' ||
          key == 'PavilionAngle' ||
          key == 'CrownAngle' ||
          key == 'CrownHeight' ||
          key == 'Ratio'
        ) {
          if (item[key] >= filters[key][0] && item[key] <= filters[key][1])
            return true;
        }
        if (!filters[key].length) {
          return true;
        }
        return filters[key].find((filter) => filter === item[key]);
      });
    });
  }

  componentDidMount() {
    // if (this.state.Type === "") {
    //     axios.get(`/users/getSavedSearch/${localStorage.getItem('ID')}`).then(resp => {
    //         this.setState({
    //             getType: resp.data.getType
    //         })
    //         console.log(this.state.getType)

    //         this.setState({
    //             Type: this.state.getType
    //         })

    //     })

    // }
    // else {
    //     this.setState({

    //     })
    // }

    // axios.get(`/users/getDiamonds`).then(resp => {
    //     this.setState({
    //         data: resp.data
    //     })
    // })
    const { selectedDiamond } = this.context;
    this.setState({ Type: selectedDiamond });

    axios.get('/users/getFancyColor').then((resp) => {
      const data = resp.data;
      // Convert to lower case and remove duplicates

      const unique = [...new Set(data.map((item) => item.toLowerCase()))];

      this.setState({
        FancyColor1: unique.map(
          (item) => item.charAt(0).toUpperCase() + item.slice(1)
        ),
      });
    });
    axios.get('/users/getFancyColorIntensity').then((resp) => {
      const data = resp.data;
      // Convert to lower case and remove duplicates

      const unique = [...new Set(data.map((item) => item.toLowerCase()))];

      this.setState({
        FancyColorIntensity1: unique.map(
          (item) => item.charAt(0).toUpperCase() + item.slice(1)
        ),
      });
    });
    axios.get('/users/getFancyColorOvertone').then((resp) => {
      const data = resp.data;
      // Convert to lower case and remove duplicates

      const unique = [...new Set(data.map((item) => item.toLowerCase()))];
      this.setState({
        FancyColorOvertone1: unique.map(
          (item) => item.charAt(0).toUpperCase() + item.slice(1)
        ),
      });
    });
    $('#advancesearch').click(function () {
      $('.advancedata').slideToggle();
    });
    $('.fancy_ic').hide();
    $('#white').addClass('td_background');
    $('#white').click(function () {
      $('#white').addClass('td_background');
      $('#whitelab').removeClass('td_background');
      $('#fancylab').removeClass('td_background');
      $('#fancy').removeClass('td_background');
      $('.fancy_ic').hide();
      $('#color').show();
    });
    $('#fancy').on('click', function () {
      $('#white').removeClass('td_background');
      $('#whitelab').removeClass('td_background');
      $('#fabncylab').removeClass('td_background');
      $('#fancy').addClass('td_background');
      $('.fancy_ic').show();
      $('#color').hide();
    });
    $('#whitelab').on('click', function () {
      $('#white').removeClass('td_background');
      $('#fancy').removeClass('td_background');
      $('#fancylab').removeClass('td_background');
      $('#whitelab').addClass('td_background');
      $('.fancy_ic').hide();
      $('#color').show();
    });
    $('#fancylab').on('click', function () {
      $('#white').removeClass('td_background');
      $('#whitelab').removeClass('td_background');
      $('#fancylab').addClass('td_background');
      $('#fancy').removeClass('td_background');
      $('.fancy_ic').show();
      $('#color').hide();
    });
    this.setState({
      UserID: localStorage.getItem('ID'),
    });
    axios
      .get(`/users/getSavedSearch/${localStorage.getItem('ID')}`)
      .then((resp) => {
        this.setState({
          filterID1: resp.data.filterID,
          filterSaved: resp.data.filter,
          Type: resp.data.getType,
        });
        if (this.state.Type == '') {
          this.setState({
            Type: 'Natural',
          });
        }

        if (this.state.Type == 'Natural') {
          $('#white').addClass('td_background');
          $('#whitelab').removeClass('td_background');
          $('#fancylab').removeClass('td_background');
          $('#fancy').removeClass('td_background');
          $('.fancy_ic').hide();
          $('#color').show();
        } else {
          $('#white').removeClass('td_background');
          $('#fancy').removeClass('td_background');
          $('#fancylab').removeClass('td_background');
          $('#whitelab').addClass('td_background');
          $('.fancy_ic').hide();
          $('#color').show();
        }

        if (this.state.filterSaved != '') {
          const filterSavedKeys = Object.keys(this.state.filterSaved[0]);

          for (var i = 0; i < filterSavedKeys.length; i++) {
            if (
              filterSavedKeys[i] == 'Certificate' ||
              filterSavedKeys[i] == 'Stock'
            ) {
              var getData1 = [];
              if (this.state.filterSaved[0]['Certificate']) {
              } else {
              }
              this.state.filterSaved[0][filterSavedKeys[i]].forEach((data) => {
                getData1.push(data);
              });
              this.state.filterID1[0][filterSavedKeys[i]].forEach(
                (data1, index) => {
                  $('input[id=' + data1 + ']').val(getData1[index]);
                }
              );
            }
            if (
              filterSavedKeys[i] == 'Weight' ||
              filterSavedKeys[i] == 'NetValue' ||
              filterSavedKeys[i] == 'VendorDiscount' ||
              filterSavedKeys[i] == 'Depth' ||
              filterSavedKeys[i] == 'Table' ||
              filterSavedKeys[i] == 'Girdle' ||
              filterSavedKeys[i] == 'CrownHeight' ||
              filterSavedKeys[i] == 'RapNetPrice' ||
              filterSavedKeys[i] == 'CrownAngle' ||
              filterSavedKeys[i] == 'PavilionAngle'
            ) {
              var getData = [];
              this.state.filterSaved[0][filterSavedKeys[i]].forEach((data) => {
                getData.push(data);
              });
              this.state.filterID1[0][filterSavedKeys[i]].forEach(
                (data1, index) => {
                  $('input[id=' + data1 + ']').val(getData[index]);
                }
              );
            }
            if (
              filterSavedKeys[i] == 'FancyColor' ||
              filterSavedKeys[i] == 'FancyColorIntensity' ||
              filterSavedKeys[i] == 'FancyColorOvertone'
            ) {
              var getData = [];
              this.state.filterSaved[0][filterSavedKeys[i]].forEach((data) => {
                getData.push(data);
              });

              this.state.filterID1[0][filterSavedKeys[i]].forEach(
                (data1, index) => {
                  $('#' + data1)
                    .find('select')
                    .val(getData[index]);
                }
              );
            }
            if (this.state.filterSaved[0][filterSavedKeys[i]] != '') {
              this.state.filterSaved[0][filterSavedKeys] = [];
              this.state.filterSaved[0][filterSavedKeys[i]].forEach((data) => {
                this.state.filter[filterSavedKeys[i]].push(data);
              });
            }
            if (this.state.filterSaved[0][filterSavedKeys[i]] != '') {
              this.state.filterSaved[0][filterSavedKeys] = [];
              this.state.filterID1[0][filterSavedKeys[i]].forEach((data) => {
                this.state.filterID[filterSavedKeys[i]].push(data);
              });
            }
            if (this.state.filterID1[0][filterSavedKeys[i]] != '') {
              if (
                filterSavedKeys[i] != 'Weight' &&
                filterSavedKeys[i] != 'NetValue' &&
                filterSavedKeys[i] != 'VendorDiscount' &&
                filterSavedKeys[i] != 'RapNetPrice' &&
                filterSavedKeys[i] != 'Depth' &&
                filterSavedKeys[i] != 'Table' &&
                filterSavedKeys[i] != 'Girdle' &&
                filterSavedKeys[i] != 'PavilionAngle' &&
                filterSavedKeys[i] != 'CrownAngle' &&
                filterSavedKeys[i] != 'CrownHeight' &&
                filterSavedKeys[i] != 'Ratio' &&
                filterSavedKeys[i] != 'Certificate' &&
                filterSavedKeys[i] != 'Stock' &&
                filterSavedKeys[i] != 'FancyColor' &&
                filterSavedKeys[i] != 'FancyColorIntensity' &&
                filterSavedKeys[i] != 'FancyColorOvertone'
              ) {
                this.state.filterID1[0][filterSavedKeys[i]].forEach((data) => {
                  $('#' + data).css('background-color', '#17a2b8');
                  $('#' + data).css('color', '#fff');
                  $(":checkbox[id='" + data + "']").prop('checked', true);
                });
              }
            }
          }

          this.countData();
        }
      });
  }
  handleEX(e) {
    if (e.target.checked) {
      $('.1VG').prop('checked', false);
      $('#1VG').css({ background: '', color: '' });
      this.state.filter.CutGrade.splice('EX', 1);
      this.state.filter.Polish.splice('EX', 1);
      this.state.filter.Symmetry.splice('EX', 1);
      this.state.filter.CutGrade.splice('VG', 1);
      this.state.filter.Polish.splice('VG', 1);
      this.state.filter.Symmetry.splice('VG', 1);

      $('#' + e.target.id).css({ background: '#17a2b8', color: '#fff' });
      $('.EX').css('background-color', '#17a2b8');
      $('.EX').css('color', '#fff');
      $('.VG').css('background-color', '');
      $('.VG').css('color', '');
      $('.EX').prop('checked', true);
      $('.VG').prop('checked', false);
      this.state.filter.CutGrade.push('EX');
      this.state.filter.Polish.push('EX');
      this.state.filter.Symmetry.push('EX');
    } else {
      $('#' + e.target.id).css({ background: '', color: '' });
      $('.EX').css('background-color', '');
      $('.EX').css('color', '');
      $('.EX').prop('checked', false);
      this.state.filter.CutGrade.splice('EX', 1);
      this.state.filter.Polish.splice('EX', 1);
      this.state.filter.Symmetry.splice('EX', 1);
    }
  }
  handleVG(e) {
    if (e.target.checked) {
      $('.1EX').prop('checked', false);
      $('#1EX').css({ background: '', color: '' });
      this.state.filter.CutGrade.splice('EX', 1);
      this.state.filter.Polish.splice('EX', 1);
      this.state.filter.Symmetry.splice('EX', 1);
      $('#' + e.target.id).css({ background: '#17a2b8', color: '#fff' });
      $('.EX').css('background-color', '#17a2b8');
      $('.EX').css('color', '#fff');
      $('.VG').css('background-color', '#17a2b8');
      $('.VG').css('color', '#fff');
      $('.EX').prop('checked', true);
      $('.VG').prop('checked', true);
      this.state.filter.CutGrade.push('EX', 'VG');
      this.state.filter.Polish.push('EX', 'VG');
      this.state.filter.Symmetry.push('EX', 'VG');
    } else {
      $('#' + e.target.id).css({ background: '', color: '' });
      $('.EX').css('background-color', '');
      $('.EX').css('color', '');
      $('.VG').css('background-color', '');
      $('.VG').css('color', '');
      this.state.filter.CutGrade.splice('EX', 1);
      this.state.filter.Polish.splice('EX', 1);
      this.state.filter.Symmetry.splice('EX', 1);
      this.state.filter.CutGrade.splice('VG', 1);
      this.state.filter.Polish.splice('VG', 1);
      this.state.filter.Symmetry.splice('VG', 1);
    }
  }
  formatValue = (value) => ` ${Number(value).toFixed(0)}`;
  render() {
    console.log("Selected Diamond type is: ", this.state.Type);
    return (
      <div className="top">
        <Header />
        {this.state.btnClick === true && this.state.loader === true ? (
          <Fragment>
            <div className="loading">
              <img src="./dist/img/loading.gif" height="150" width="150"></img>
            </div>
          </Fragment>
        ) : (
          ''
        )}
        {/* {this.state.loader ? <div className="loading"><img src="./dist/img/loading.gif" height="150" width="150"></img></div> : ''} */}
        <div className="content-wrapper">
          <div className="container-fluid">
            <section className="search_section pt-1 ">
              <div className="container">
                <form>
                  <div className="row  mobileset">
                    <div className="col-md-4 ">
                      <DebounceInput
                        name="Certificate"
                        id="Certificate1"
                        placeholder="CERTIFICATE NO || STONE NO"
                        className="form-control"
                        style={{ width: '70%' }}
                        value={this.state.Certificate}
                        onChange={this.handleCertificateChange}
                        debounceTimeout={500}
                      />
                    </div>
                  </div>
                  <div className="card card-default">
                    <div
                      className="card-header bg-secondary"
                      style={{ borderRadius: '20px 20px 0px 0px' }}
                    >
                      <div className="card-title ">Basic Search</div>
                      <div class="card-tools pr-3">
                        <button
                          type="button"
                          class="btn btn-tool"
                          data-card-widget="collapse"
                        >
                          <i class="fas fa-minus"></i>
                        </button>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="row type pt-1">
                        <div className="col-md-1">
                          <label>{t('type')}</label>
                        </div>
                        <div className="col-md-8">
                          <table>
                            <tr>
                              <td id="white" name="Natural">
                                <div
                                  id="Natural"
                                  onClick={this.handleTypeChange}
                                >
                                  <a
                                    id="Natural"
                                    onClick={this.handleTypeChange}
                                  >
                                    {t('white')}
                                  </a>
                                </div>
                              </td>
                              <td id="fancy">
                                <div
                                  id="Natural"
                                  onClick={this.handleTypeChange}
                                >
                                  <a
                                    id="Natural"
                                    onClick={this.handleTypeChange}
                                  >
                                    {t('fancy')}
                                  </a>
                                </div>
                              </td>
                              <td id="whitelab">
                                <div
                                  id="Labgrown"
                                  onClick={this.handleTypeChange}
                                >
                                  <a
                                    id="Labgrown"
                                    onClick={this.handleTypeChange}
                                  >
                                    {t('labGrownWhite')}
                                  </a>
                                </div>
                              </td>
                              <td id="fancylab">
                                <div
                                  id="Labgrown"
                                  onClick={this.handleTypeChange}
                                >
                                  <a
                                    id="Labgrown"
                                    onClick={this.handleTypeChange}
                                  >
                                    {t('labGrownFancy')}
                                  </a>
                                </div>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      <div className="row ">
                        <div className="col-md-1">
                          <label>{t('shape')}</label>
                        </div>
                        <div className="col-md-11">
                          <table>
                            <tr id="shape">
                              {/* <td name="shape" onClick={this.handleChage} value="Round">
                                                                    <img src="./images/round-brilliant.svg" value="Round" />
                                                                    <p value="Round">Round</p>
                                                                </td> */}

                              <label id="RBC" value="RBC">
                                <td>
                                  <input
                                    id="RBC"
                                    type="checkbox"
                                    value="RBC"
                                    onChange={this.handleChage}
                                  />
                                  <img
                                    src="./dist/img/round-brilliant.svg"
                                    value="RBC"
                                  />
                                  <p value="RBC">{t('round')}</p>{' '}
                                </td>
                              </label>
                              {/* <input type="checkbox" value="Round" onChange={this.handleChage} /> */}
                              <label id="Oval" value="OV">
                                <td>
                                  <input
                                    id="Oval"
                                    type="checkbox"
                                    value="OV"
                                    onChange={this.handleChage}
                                  />
                                  <img src="./dist/img/oval.svg" value="Oval" />
                                  <p value="OV">{t('oval')}</p>{' '}
                                </td>
                              </label>
                              <label id="Cushion">
                                <td>
                                  <input
                                    id="Cushion"
                                    type="checkbox"
                                    value="CUSH"
                                    onChange={this.handleChage}
                                  />
                                  <img
                                    src="./dist/img/cushion.svg"
                                    value="Cushion"
                                  />
                                  <p value="CUSH">{t('cushion')}</p>{' '}
                                </td>
                              </label>
                              <label id="Pear">
                                <td>
                                  <input
                                    id="Pear"
                                    type="checkbox"
                                    value="PS"
                                    onChange={this.handleChage}
                                  />
                                  <img
                                    src="./dist/img/pear-shape.svg"
                                    value="Pear"
                                  />
                                  <p value="PS">{t('pear')}</p>{' '}
                                </td>
                              </label>
                              <label id="Heart">
                                <td>
                                  <input
                                    id="Heart"
                                    type="checkbox"
                                    value="HS"
                                    onChange={this.handleChage}
                                  />
                                  <img
                                    src="./dist/img/heart.svg"
                                    value="Heart"
                                  />
                                  <p value="HS">{t('heart')}</p>{' '}
                                </td>
                              </label>
                              <label id="Marquise">
                                <td>
                                  <input
                                    id="Marquise"
                                    type="checkbox"
                                    value="MQ"
                                    onChange={this.handleChage}
                                  />
                                  <img
                                    src="./dist/img/marquise.svg"
                                    value="Marquise"
                                  />
                                  <p value="MQ">{t('marquise')}</p>{' '}
                                </td>
                              </label>
                              <label id="Princess">
                                <td>
                                  <input
                                    id="Princess"
                                    type="checkbox"
                                    value="PRIN"
                                    onChange={this.handleChage}
                                  />
                                  <img
                                    src="./dist/img/icon_shape.svg"
                                    value="Princess"
                                  />
                                  <p value="PRIN">{t('princess')}</p>{' '}
                                </td>
                              </label>
                              <label id="eme">
                                <td>
                                  <input
                                    id="eme"
                                    type="checkbox"
                                    value="EM"
                                    onChange={this.handleChage}
                                  />
                                  <img
                                    src="./dist/img/emerald-cut.svg"
                                    value="Emerald"
                                  />
                                  <p value="EM">{t('emerald')}</p>{' '}
                                </td>
                              </label>

                              <label id="Radiant">
                                <td>
                                  <input
                                    id="Radiant"
                                    type="checkbox"
                                    value="RAD"
                                    onChange={this.handleChage}
                                  />
                                  <img
                                    src="./dist/img/radiant.svg"
                                    value="Radiant"
                                  />
                                  <p value="RAD">{t('radiant')}</p>{' '}
                                </td>
                              </label>
                              <label id="Triangle">
                                <td>
                                  <input
                                    id="Triangle"
                                    type="checkbox"
                                    value="TRI"
                                    onChange={this.handleChage}
                                  />
                                  <img
                                    src="./dist/img/oval.svg"
                                    value="Triangle"
                                  />
                                  <p value="TRI">{t('triangle')}</p>{' '}
                                </td>
                              </label>
                              <label id="Other">
                                <td>
                                  <input
                                    id="Other"
                                    type="checkbox"
                                    value="OTHER"
                                    onChange={this.handleChage}
                                  />
                                  <img
                                    src="./dist/img/oval.svg"
                                    value="Other"
                                  />
                                  <p value="OTHER">{t('other')}</p>{' '}
                                </td>
                              </label>
                            </tr>
                          </table>
                        </div>
                      </div>
                      <div id="weight" className="row pt-2  ">
                        <div className="col-md-1">
                          <label>{t('weight')}</label>
                        </div>
                        <div className="col-md-2">
                          <table>
                            <tbody>
                              <tr id="weight1">
                                <DebounceInput
                                  type="text"
                                  id="weight1"
                                  name="Weight"
                                  placeholder={`${t('from')}`}
                                  className="form-control"
                                  min="0"
                                  max="10"
                                  value={this.state.Weight1}
                                  debounceTimeout={500}
                                  onChange={this.handleWeightChange}
                                />
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="col-md-2">
                          <table>
                            <tbody>
                              <tr id="weight2">
                                <DebounceInput
                                  type="text"
                                  id="weight2"
                                  name="Weight"
                                  placeholder={`${t('to')}`}
                                  className="form-control"
                                  min="0"
                                  max="10"
                                  value={this.state.Weight2}
                                  debounceTimeout={500}
                                  onChange={this.handleWeightChange}
                                />
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div className="fancy_ic">
                        <div className="row pt-2 pb-2">
                          <div className="col-md-1">
                            <label>{t('fancy')}</label>
                          </div>

                          <div className="col-md-3">
                            <table>
                              <tbody>
                                <tr id="fancycolor">
                                  <select
                                    id="fancycolor"
                                    className="form-control"
                                    onChange={this.handleChage}
                                  >
                                    <option selected>FANCY COLOR</option>
                                    {this.state.FancyColor1.map((data) =>
                                      data != '' ? (
                                        <option id={data} value={data}>
                                          {data}
                                        </option>
                                      ) : (
                                        ''
                                      )
                                    )}
                                  </select>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="col-md-3">
                            <table>
                              <tbody>
                                <tr id="fancycolorintensity">
                                  <select
                                    id="fancycolorintensity"
                                    className="form-control"
                                    onChange={this.handleChage}
                                  >
                                    <option selected>
                                      FANCY COLOR INTENSITY
                                    </option>
                                    {this.state.FancyColorIntensity1.map(
                                      (data) =>
                                        data != '' ? (
                                          <option id={data} value={data}>
                                            {data}
                                          </option>
                                        ) : (
                                          ''
                                        )
                                    )}
                                  </select>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="col-md-3">
                            <table>
                              <tbody>
                                <tr id="fancycolorovertone">
                                  <select
                                    id="fancycolorovertone"
                                    className="form-control"
                                    onChange={this.handleChage}
                                  >
                                    <option selected>
                                      FANCY COLOR OVERTONE
                                    </option>
                                    {this.state.FancyColorOvertone1.map(
                                      (data) =>
                                        data != '' ? (
                                          <option id={data} value={data}>
                                            {data}
                                          </option>
                                        ) : (
                                          ''
                                        )
                                    )}
                                  </select>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div id="color" className="row pt-2  ">
                        <div className="col-md-1">
                          <label>{t('color')}</label>
                        </div>
                        <div className="col-md-11  ">
                          <table>
                            <tbody>
                              <tr id="color2">
                                <label id="c21">
                                  <td>
                                    <input
                                      id="c21"
                                      type="checkbox"
                                      value="D"
                                      onChange={this.handleChage}
                                    />
                                    <p value="c21">D</p>{' '}
                                  </td>
                                </label>
                                <label id="c22">
                                  <td>
                                    <input
                                      id="c22"
                                      type="checkbox"
                                      value="E"
                                      onChange={this.handleChage}
                                    />
                                    <p value="c22">E</p>{' '}
                                  </td>
                                </label>
                                <label id="c23">
                                  <td>
                                    <input
                                      id="c23"
                                      type="checkbox"
                                      value="F"
                                      onChange={this.handleChage}
                                    />
                                    <p value="c23">F</p>{' '}
                                  </td>
                                </label>
                                <label id="c24">
                                  <td>
                                    <input
                                      id="c24"
                                      type="checkbox"
                                      value="G"
                                      onChange={this.handleChage}
                                    />
                                    <p value="c24">G</p>{' '}
                                  </td>
                                </label>
                                <label id="c25">
                                  <td>
                                    <input
                                      id="c25"
                                      type="checkbox"
                                      value="H"
                                      onChange={this.handleChage}
                                    />
                                    <p value="c25">H</p>{' '}
                                  </td>
                                </label>
                                <label id="c26">
                                  <td>
                                    <input
                                      id="c26"
                                      type="checkbox"
                                      value="I"
                                      onChange={this.handleChage}
                                    />
                                    <p value="c26">I</p>
                                  </td>
                                </label>
                                <label id="c27">
                                  <td>
                                    <input
                                      id="c27"
                                      type="checkbox"
                                      value="J"
                                      onChange={this.handleChage}
                                    />
                                    <p value="c27">J</p>{' '}
                                  </td>
                                </label>
                                <label id="c28">
                                  <td>
                                    <input
                                      id="c28"
                                      type="checkbox"
                                      value="K"
                                      onChange={this.handleChage}
                                    />
                                    <p value="c28">K</p>{' '}
                                  </td>
                                </label>
                                <label id="c29">
                                  <td>
                                    <input
                                      id="c29"
                                      type="checkbox"
                                      value="L"
                                      onChange={this.handleChage}
                                    />
                                    <p value="c29">L</p>
                                  </td>
                                </label>
                                <label id="c210">
                                  <td>
                                    <input
                                      id="c210"
                                      type="checkbox"
                                      value="M"
                                      onChange={this.handleChage}
                                    />
                                    <p value="c210">M</p>{' '}
                                  </td>
                                </label>
                                <label id="c211">
                                  <td>
                                    <input
                                      id="c211"
                                      type="checkbox"
                                      value="N"
                                      onChange={this.handleChage}
                                    />
                                    <p value="c211">N</p>{' '}
                                  </td>
                                </label>
                                <label id="c212">
                                  <td>
                                    <input
                                      id="c212"
                                      type="checkbox"
                                      value="O-Z"
                                      onChange={this.handleChage}
                                    />
                                    <p value="c212">O-Z</p>{' '}
                                  </td>
                                </label>
                                <label id="c213">
                                  <td>
                                    <input
                                      id="c213"
                                      type="checkbox"
                                      value="NONE"
                                      onChange={this.handleChage}
                                    />
                                    <p value="c213">NN</p>{' '}
                                  </td>
                                </label>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="row  ">
                        <div className="col-md-1">
                          <label>{t('clarity')}</label>
                        </div>
                        <div className="col-md-11  ">
                          <table>
                            <tbody>
                              <tr id="clarity">
                                <label id="clFL">
                                  <td>
                                    <input
                                      id="clFL"
                                      type="checkbox"
                                      value="FL"
                                      onChange={this.handleChage}
                                    />
                                    <p value="clFL">FL</p>{' '}
                                  </td>
                                </label>
                                <label id="clIF">
                                  <td>
                                    <input
                                      id="clIF"
                                      type="checkbox"
                                      value="IF"
                                      onChange={this.handleChage}
                                    />
                                    <p value="clIF">IF</p>{' '}
                                  </td>
                                </label>
                                <label id="clVVS1">
                                  <td>
                                    <input
                                      id="clVVS1"
                                      type="checkbox"
                                      value="VVS1"
                                      onChange={this.handleChage}
                                    />
                                    <p value="clVVS1">VVS1</p>{' '}
                                  </td>
                                </label>
                                <label id="clVVS2">
                                  <td>
                                    <input
                                      id="clVVS2"
                                      type="checkbox"
                                      value="VVS2"
                                      onChange={this.handleChage}
                                    />
                                    <p value="clVVS2">VVS2</p>{' '}
                                  </td>
                                </label>
                                <label id="clVS1">
                                  <td>
                                    <input
                                      id="clVS1"
                                      type="checkbox"
                                      value="VS1"
                                      onChange={this.handleChage}
                                    />
                                    <p value="clVS1">VS1</p>{' '}
                                  </td>
                                </label>
                                <label id="clVS2">
                                  <td>
                                    <input
                                      id="clVS2"
                                      type="checkbox"
                                      value="VS2"
                                      onChange={this.handleChage}
                                    />
                                    <p value="clVS2">VS2</p>
                                  </td>
                                </label>
                                <label id="clSI1">
                                  <td>
                                    <input
                                      id="clSI1"
                                      type="checkbox"
                                      value="SI1"
                                      onChange={this.handleChage}
                                    />
                                    <p value="clSI1">SI1</p>
                                  </td>
                                </label>
                                <label id="clSI2">
                                  <td>
                                    <input
                                      id="clSI2"
                                      type="checkbox"
                                      value="SI2"
                                      onChange={this.handleChage}
                                    />
                                    <p value="clSI2">SI2</p>
                                  </td>
                                </label>
                                <label id="clSI3">
                                  <td>
                                    <input
                                      id="clSI3"
                                      type="checkbox"
                                      value="SI3"
                                      onChange={this.handleChage}
                                    />
                                    <p value="clSI3">SI3</p>
                                  </td>
                                </label>
                                <label id="clI1">
                                  <td>
                                    <input
                                      id="clI1"
                                      type="checkbox"
                                      value="I1"
                                      onChange={this.handleChage}
                                    />
                                    <p value="clI1">I1</p>
                                  </td>
                                </label>
                                <label id="clI2">
                                  <td>
                                    <input
                                      id="clI2"
                                      type="checkbox"
                                      value="I2"
                                      onChange={this.handleChage}
                                    />
                                    <p value="clI2">I2</p>
                                  </td>
                                </label>
                                <label id="clI3">
                                  <td>
                                    <input
                                      id="clI3"
                                      type="checkbox"
                                      value="I3"
                                      onChange={this.handleChage}
                                    />
                                    <p value="clI3">I3</p>
                                  </td>
                                </label>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="row  ">
                        <div className="col-md-1">
                          <label>{t('cut')}</label>
                        </div>
                        <div className="col-md-4  ">
                          <table>
                            <tbody>
                              <tr id="cut">
                                <label id="EX">
                                  <td>
                                    <input
                                      id="EX"
                                      type="checkbox"
                                      value="EX"
                                      onChange={this.handleChage}
                                    />
                                    <p value="EX">EX</p>{' '}
                                  </td>
                                </label>
                                <label id="VG">
                                  <td>
                                    <input
                                      id="VG"
                                      type="checkbox"
                                      value="VG"
                                      onChange={this.handleChage}
                                    />
                                    <p value="VG">VG</p>{' '}
                                  </td>
                                </label>
                                <label id="GD">
                                  <td>
                                    <input
                                      id="GD"
                                      type="checkbox"
                                      value="GD"
                                      onChange={this.handleChage}
                                    />
                                    <p value="GD">GD</p>{' '}
                                  </td>
                                </label>
                                <label id="FR">
                                  <td>
                                    <input
                                      id="FR"
                                      type="checkbox"
                                      value="F"
                                      onChange={this.handleChage}
                                    />
                                    <p value="FR">FR</p>{' '}
                                  </td>
                                </label>
                                <label id="PR">
                                  <td>
                                    <input
                                      id="PR"
                                      type="checkbox"
                                      value="P"
                                      onChange={this.handleChage}
                                    />
                                    <p value="PR">PR</p>{' '}
                                  </td>
                                </label>
                                <label id="NN">
                                  <td>
                                    <input
                                      id="NN"
                                      type="checkbox"
                                      value="NONE"
                                      onChange={this.handleChage}
                                    />
                                    <p value="NN">NN</p>
                                  </td>
                                </label>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="col-md-7">
                          <table>
                            <tr id="shortcut">
                              <label id="1EX">
                                <td>
                                  <input
                                    id="1EX"
                                    className="1EX"
                                    type="checkbox"
                                    value="EX"
                                    onChange={this.handleEX}
                                  />
                                  <p value="EX">3EX</p>{' '}
                                </td>
                              </label>
                              <label id="1VG">
                                <td>
                                  <input
                                    id="1VG"
                                    type="checkbox"
                                    className="1VG"
                                    value="VG"
                                    onChange={this.handleVG}
                                  />
                                  <p value="VG">VG+</p>{' '}
                                </td>
                              </label>
                            </tr>
                          </table>
                        </div>
                      </div>
                      <div className="row  ">
                        <div className="col-md-1">
                          <label>{t('polish')}</label>
                        </div>
                        <div className="col-md-11  ">
                          <table>
                            <tbody>
                              <tr id="polish">
                                <label id="pEX">
                                  <td>
                                    <input
                                      id="pEX"
                                      type="checkbox"
                                      value="EX"
                                      onChange={this.handleChage}
                                    />
                                    <p value="pEX">EX</p>{' '}
                                  </td>
                                </label>
                                <label id="pVG">
                                  <td>
                                    <input
                                      id="pVG"
                                      type="checkbox"
                                      value="VG"
                                      onChange={this.handleChage}
                                    />
                                    <p value="pVG">VG</p>{' '}
                                  </td>
                                </label>
                                <label id="pGD">
                                  <td>
                                    <input
                                      id="pGD"
                                      type="checkbox"
                                      value="GD"
                                      onChange={this.handleChage}
                                    />
                                    <p value="pGD">GD</p>{' '}
                                  </td>
                                </label>
                                <label id="pFR">
                                  <td>
                                    <input
                                      id="pFR"
                                      type="checkbox"
                                      value="F"
                                      onChange={this.handleChage}
                                    />
                                    <p value="pFR">FR</p>{' '}
                                  </td>
                                </label>
                                <label id="pPR">
                                  <td>
                                    <input
                                      id="pPR"
                                      type="checkbox"
                                      value="P"
                                      onChange={this.handleChage}
                                    />
                                    <p value="pPR">PR</p>{' '}
                                  </td>
                                </label>
                                <label id="pNN">
                                  <td>
                                    <input
                                      id="pNN"
                                      type="checkbox"
                                      value="N"
                                      onChange={this.handleChage}
                                    />
                                    <p value="pNN">NN</p>
                                  </td>
                                </label>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="row  ">
                        <div className="col-md-1">
                          <label>{t('symmetry')}</label>
                        </div>
                        <div className="col-md-11  ">
                          <table>
                            <tbody>
                              <tr id="SYMM">
                                <label id="sEX">
                                  <td>
                                    <input
                                      id="sEX"
                                      type="checkbox"
                                      value="EX"
                                      onChange={this.handleChage}
                                    />
                                    <p value="sEX">EX</p>{' '}
                                  </td>
                                </label>
                                <label id="sVG">
                                  <td>
                                    <input
                                      id="sVG"
                                      type="checkbox"
                                      value="VG"
                                      onChange={this.handleChage}
                                    />
                                    <p value="sVG">VG</p>{' '}
                                  </td>
                                </label>
                                <label id="sGD">
                                  <td>
                                    <input
                                      id="sGD"
                                      type="checkbox"
                                      value="GD"
                                      onChange={this.handleChage}
                                    />
                                    <p value="sGD">GD</p>{' '}
                                  </td>
                                </label>
                                <label id="sFR">
                                  <td>
                                    <input
                                      id="sFR"
                                      type="checkbox"
                                      value="F"
                                      onChange={this.handleChage}
                                    />
                                    <p value="sFR">FR</p>{' '}
                                  </td>
                                </label>
                                <label id="sPR">
                                  <td>
                                    <input
                                      id="sPR"
                                      type="checkbox"
                                      value="P"
                                      onChange={this.handleChage}
                                    />
                                    <p value="sPR">PR</p>{' '}
                                  </td>
                                </label>
                                <label id="sNN">
                                  <td>
                                    <input
                                      id="sNN"
                                      type="checkbox"
                                      value="N"
                                      onChange={this.handleChage}
                                    />
                                    <p value="sNN">NN</p>
                                  </td>
                                </label>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="row  ">
                        <div className="col-md-1">
                          <label>{t('flourocense')}</label>
                        </div>
                        <div className="col-md-5">
                          <table>
                            <tbody>
                              <tr id="flour">
                                <label id="N">
                                  <td>
                                    <input
                                      id="N"
                                      type="checkbox"
                                      value="N"
                                      onChange={this.handleChage}
                                    />
                                    <p value="N">N</p>
                                  </td>
                                </label>
                                <label id="F">
                                  <td>
                                    <input
                                      id="F"
                                      type="checkbox"
                                      value="F"
                                      onChange={this.handleChage}
                                    />
                                    <p value="F">F</p>
                                  </td>
                                </label>
                                <label id="M">
                                  <td>
                                    <input
                                      id="M"
                                      type="checkbox"
                                      value="M"
                                      onChange={this.handleChage}
                                    />
                                    <p value="M">M</p>
                                  </td>
                                </label>
                                <label id="S">
                                  <td>
                                    <input
                                      id="S"
                                      type="checkbox"
                                      value="S"
                                      onChange={this.handleChage}
                                    />
                                    <p value="S">S</p>
                                  </td>
                                </label>
                                <label id="VS">
                                  <td>
                                    <input
                                      id="VS"
                                      type="checkbox"
                                      value="VS"
                                      onChange={this.handleChage}
                                    />
                                    <p value="VS">VS</p>
                                  </td>
                                </label>
                                <label id="FNN">
                                  <td>
                                    <input
                                      id="FNN"
                                      type="checkbox"
                                      value="N"
                                      onChange={this.handleChage}
                                    />
                                    <p value="FNN">NN</p>
                                  </td>
                                </label>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="col-md-1">
                          <label>{t('location')}</label>
                        </div>
                        <div className="col-md-5">
                          <table>
                            <tbody>
                              <tr id="Country">
                                <label id="HongKong">
                                  <td>
                                    <input
                                      id="HongKong"
                                      type="checkbox"
                                      value="Hong Kong"
                                      onChange={this.handleChage}
                                    />
                                    <p value="HongKong">{t('hongKong')}</p>
                                  </td>
                                </label>
                                <label id="INDIA">
                                  <td>
                                    <input
                                      id="INDIA"
                                      type="checkbox"
                                      value="INDIA"
                                      onChange={this.handleChage}
                                    />
                                    <p value="INDIA">{t('india')}</p>
                                  </td>
                                </label>
                                <label id="USA">
                                  <td>
                                    <input
                                      id="USA"
                                      type="checkbox"
                                      value="USA"
                                      onChange={this.handleChage}
                                    />
                                    <p value="USA">{t('usa')}</p>
                                  </td>
                                </label>
                                <label id="DUBAI">
                                  <td>
                                    <input
                                      id="DUBAI"
                                      type="checkbox"
                                      value="DUBAI"
                                      onChange={this.handleChage}
                                    />
                                    <p value="DUBAI">{t('dubai')}</p>
                                  </td>
                                </label>
                                <label id="BELGIUM">
                                  <td>
                                    <input
                                      id="BELGIUM"
                                      type="checkbox"
                                      value="BELGIUM"
                                      onChange={this.handleChage}
                                    />
                                    <p value="BELGIUM">{t('belgium')}</p>
                                  </td>
                                </label>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="row  ">
                        <div className="col-md-1">
                          <label>{t('certificate')}</label>
                        </div>
                        <div className="col-md-5  ">
                          <table>
                            <tbody>
                              <tr id="cert">
                                <label id="GIA">
                                  <td>
                                    <input
                                      id="GIA"
                                      type="checkbox"
                                      value="GIA"
                                      onChange={this.handleChage}
                                    />
                                    <p value="GIA">GIA</p>
                                  </td>
                                </label>

                                <label id="IGI">
                                  <td>
                                    <input
                                      id="IGI"
                                      type="checkbox"
                                      value="IGI"
                                      onChange={this.handleChage}
                                    />
                                    <p value="IGI">IGI</p>
                                  </td>
                                </label>

                                <label id="HRD">
                                  <td>
                                    <input
                                      id="HRD"
                                      type="checkbox"
                                      value="HRD"
                                      onChange={this.handleChage}
                                    />
                                    <p value="HRD">HRD</p>
                                  </td>
                                </label>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-1">
                          <label>{t('price')}</label>
                        </div>
                        <div className="col-md-2">
                          <table>
                            <tbody>
                              <tr id="netValue1">
                                <DebounceInput
                                  type="number"
                                  id="netValue1"
                                  name=""
                                  placeholder={`${t('from')}`}
                                  className="form-control"
                                  min="0"
                                  max="10"
                                  value={this.state.netValue1}
                                  debounceTimeout={500}
                                  onChange={this.handlePriceChange}
                                />
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="col-md-2">
                          <table>
                            <tbody>
                              <tr id="netValue2">
                                <DebounceInput
                                  type="number"
                                  id="netValue2"
                                  name="netValue"
                                  placeholder={`${t('to')}`}
                                  className="form-control"
                                  min="0"
                                  max="10"
                                  value={this.state.netValue2}
                                  debounceTimeout={500}
                                  onChange={this.handlePriceChange}
                                />
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="col-md-1"></div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-md-5"></div>
                      <div className="col-md-4  mobilebutton">
                        <button
                          type="submit"
                          className="btn btn-info btn_search"
                          onClick={this.handleClick}
                        >
                          <i class="fas fa-search"></i>
                        </button>
                        <button
                          type="reset"
                          className="btn btn-info btn_reset"
                          onClick={this.resetData}
                        >
                          <i class="fas fa-sync-alt"></i>
                        </button>
                      </div>
                    </div>

                    <div
                      className="card-header bg-secondary"
                      id="advancesearch"
                    >
                      <div className="card-title">{t('advanceSearch')}</div>
                    </div>
                    <div
                      className="card-body advancedata"
                      style={{ display: 'none' }}
                    >
                      <div>
                        <b>{`${t('price')} & ${t('comments')}`}</b>
                      </div>
                      <div className="row">
                        <div className="col-md-1">
                          <label>Rap Discount</label>
                        </div>
                        <div
                          className="col-md-3 "
                          style={{ display: 'inline-flex' }}
                        >
                          <table>
                            <tbody>
                              <tr id="VendorDiscount1">
                                <DebounceInput
                                  debounceTimeout={500}
                                  type="text"
                                  name="from_Discount"
                                  id="VendorDiscount1"
                                  placeholder={`${t('from')}`}
                                  className="form-control"
                                  value={this.state.VendorDiscount1}
                                  onChange={this.handleVendorDiscountChange}
                                />
                              </tr>
                            </tbody>
                          </table>
                          <table>
                            <tbody>
                              <tr id="VendorDiscount2">
                                <DebounceInput
                                  debounceTimeout={500}
                                  type="text"
                                  name="to_Discount"
                                  id="VendorDiscount2"
                                  placeholder={`${t('to')}`}
                                  className="form-control ml-1"
                                  value={this.state.VendorDiscount2}
                                  onChange={this.handleVendorDiscountChange}
                                />
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <div className="col-md-1">{`${t('price')} / ${t('carat')}`}</div>
                        <div
                          className="col-md-3"
                          style={{ display: 'inline-flex' }}
                        >
                          <table>
                            <tbody>
                              <tr id="PricePerCarat1">
                                <DebounceInput
                                  id="PricePerCarat1"
                                  value={this.state.PricePerCarat1}
                                  onChange={this.handlePricePerCaratChange}
                                  type="text"
                                  name="from_price"
                                  placeholder={`${t('from')}`}
                                  className="form-control"
                                  debounceTimeout={500}
                                />
                              </tr>
                            </tbody>
                          </table>
                          <table>
                            <tbody>
                              <tr id="PricePerCarat2">
                                <DebounceInput
                                  type="text"
                                  id="PricePerCarat2"
                                  value={this.state.PricePerCarat2}
                                  onChange={this.handlePricePerCaratChange}
                                  name="to_price"
                                  placeholder={`${t('to')}`}
                                  className="form-control ml-1"
                                  debounceTimeout={500}
                                />
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div>
                        <b>{t('parameters')}</b>
                      </div>
                      <div className="row">
                        <div className="col-md-1">
                          <label>{`${t('depth')}(%)`}</label>
                        </div>
                        <div
                          className="col-md-3"
                          style={{ display: 'inline-flex' }}
                        >
                          <table>
                            <tbody>
                              <tr id="Depth1">
                                <DebounceInput
                                  debounceTimeout={500}
                                  id="Depth1"
                                  type="text"
                                  name="from_depth"
                                  placeholder={`${t('from')}`}
                                  className="form-control"
                                  value={this.state.Depth1}
                                  onChange={this.handleDepthChange}
                                />
                              </tr>
                            </tbody>
                          </table>

                          <table>
                            <tbody>
                              <tr id="Depth2">
                                <DebounceInput
                                  debounceTimeout={500}
                                  id="Depth2"
                                  type="text"
                                  name="to_depth"
                                  placeholder={`${t('to')}`}
                                  className="form-control ml-1"
                                  value={this.state.Depth2}
                                  onChange={this.handleDepthChange}
                                />
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <div className="col-md-1">{`${t('table')}(%)`}</div>
                        <div
                          className="col-md-3"
                          style={{ display: 'inline-flex' }}
                        >
                          <tabel>
                            <tbody>
                              <tr id="Table1">
                                <DebounceInput
                                  debounceTimeout={500}
                                  type="text"
                                  id="Table1"
                                  value={this.state.Table1}
                                  onChange={this.handleTableChange}
                                  name="from_table"
                                  placeholder={`${t('from')}`}
                                  className="form-control"
                                />
                              </tr>
                            </tbody>
                          </tabel>
                          <table>
                            <tbody>
                              <tr id="Table2">
                                <DebounceInput
                                  debounceTimeout={500}
                                  type="text"
                                  id="Table2"
                                  name="to_table"
                                  value={this.state.Table2}
                                  onChange={this.handleTableChange}
                                  placeholder={`${t('to')}`}
                                  className="form-control ml-1"
                                />
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <div className="col-md-1">{`${t('girdle')}(%)`}</div>
                        <div
                          className="col-md-3"
                          style={{ display: 'inline-flex' }}
                        >
                          <table>
                            <tbody>
                              <tr id="Girdle1">
                                <DebounceInput
                                  debounceTimeout={500}
                                  id="Girdle1"
                                  value={this.state.Girdle1}
                                  type="text"
                                  name="from_girdle"
                                  placeholder={`${t('from')}`}
                                  className="form-control"
                                  onChange={this.handleGirdleChange}
                                />
                              </tr>
                            </tbody>
                          </table>

                          <table>
                            <tbody>
                              <tr id="Girdle2">
                                <DebounceInput
                                  debounceTimeout={500}
                                  id="Girdle2"
                                  value={this.state.Girdle2}
                                  type="text"
                                  name="to_girdle"
                                  placeholder={`${t('to')}`}
                                  className="form-control ml-1"
                                  onChange={this.handleGirdleChange}
                                />
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="row pt-1">
                        <div className="col-md-1">
                          <label>P.Angle</label>
                        </div>
                        <div
                          className="col-md-3"
                          style={{ display: 'inline-flex' }}
                        >
                          <table>
                            <tbody>
                              <tr id="PavilionAngle1">
                                <DebounceInput
                                  debounceTimeout={500}
                                  type="text"
                                  id="PavilionAngle1"
                                  value={this.state.PavilionAngle1}
                                  name="from_pangle"
                                  placeholder={`${t('from')}`}
                                  className="form-control"
                                  onChange={this.handlePavilionAngleChange}
                                />
                              </tr>
                            </tbody>
                          </table>
                          <table>
                            <tbody>
                              <tr id="PavilionAngle2">
                                <DebounceInput
                                  debounceTimeout={500}
                                  type="text"
                                  id="PavilionAngle2"
                                  value={this.state.PavilionAngle2}
                                  name="to_pangle"
                                  placeholder={`${t('to')}`}
                                  className="form-control ml-1"
                                  onChange={this.handlePavilionAngleChange}
                                />
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <div className="col-md-1">C.Angle</div>
                        <div
                          className="col-md-3"
                          style={{ display: 'inline-flex' }}
                        >
                          <table>
                            <tbody>
                              <tr id="CrownAngle1">
                                <DebounceInput
                                  debounceTimeout={500}
                                  type="text"
                                  id="CrownAngle1"
                                  name="from_cangle"
                                  placeholder={`${t('from')}`}
                                  className="form-control"
                                  value={this.state.CrownAngle1}
                                  onChange={this.handleCrownAngleChange}
                                />
                              </tr>
                            </tbody>
                          </table>
                          <table>
                            <tbody>
                              <tr id="CrownAngle2">
                                <DebounceInput
                                  debounceTimeout={500}
                                  type="text"
                                  id="CrownAngle2"
                                  name="to_cangle"
                                  placeholder={`${t('to')}`}
                                  className="form-control ml-1"
                                  value={this.state.CrownAngle2}
                                  onChange={this.handleCrownAngleChange}
                                />
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <div className="col-md-1">C.Height</div>
                        <div
                          className="col-md-3"
                          style={{ display: 'inline-flex' }}
                        >
                          <table>
                            <tbody>
                              <tr id="CrownHeight1">
                                <DebounceInput
                                  debounceTimeout={500}
                                  type="text"
                                  id="CrownHeight1"
                                  name="from_cheight"
                                  placeholder={`${t('from')}`}
                                  className="form-control"
                                  value={this.state.CrownHeight1}
                                  onChange={this.handleCrownHeightChange}
                                />
                              </tr>
                            </tbody>
                          </table>
                          <table>
                            <tbody>
                              <tr id="CrownHeight2">
                                <DebounceInput
                                  debounceTimeout={500}
                                  type="text"
                                  id="CrownHeight2"
                                  name="to_cheight"
                                  placeholder={`${t('to')}`}
                                  className="form-control ml-1"
                                  value={this.state.CrownHeight2}
                                  onChange={this.handleCrownHeightChange}
                                />
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="row pt-1">
                        <div className="col-md-1">{`${t('ratio')}`}</div>
                        <div
                          className="col-md-3"
                          style={{ display: 'inline-flex' }}
                        >
                          <table>
                            <tbody>
                              <tr id="Ratio1">
                                <DebounceInput
                                  id="Ratio1"
                                  type="text"
                                  name="from_ratio"
                                  placeholder={`${t('from')}`}
                                  className="form-control"
                                  value={this.state.Ratio1}
                                  onChange={this.handleRatioChange}
                                />
                              </tr>
                            </tbody>
                          </table>
                          <table>
                            <tbody>
                              <tr id="Ratio2">
                                <DebounceInput
                                  type="text"
                                  id="Ratio2"
                                  name="to_ratio"
                                  placeholder={`${t('to')}`}
                                  className="form-control ml-1"
                                  value={this.state.Ratio2}
                                  onChange={this.handleRatioChange}
                                />
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="col-md-1">
                          <label>{`${t('minDiameter')}`}</label>
                        </div>
                        <div
                          className="col-md-3"
                          style={{ display: 'inline-flex' }}
                        >
                          <input
                            type="text"
                            name="from_pangle"
                            placeholder={`${t('from')}`}
                            className="form-control"
                          />
                          <input
                            type="text"
                            name="to_pangle"
                            placeholder={`${t('to')}`}
                            className="form-control ml-1"
                          />
                        </div>

                        <div className="col-md-1">{`${t('maxDiameter')}`}</div>
                        <div
                          className="col-md-3"
                          style={{ display: 'inline-flex' }}
                        >
                          <input
                            type="text"
                            name="from_cangle"
                            placeholder={`${t('from')}`}
                            className="form-control"
                          />
                          <input
                            type="text"
                            name="to_cangle"
                            placeholder={`${t('to')}`}
                            className="form-control ml-1"
                          />
                        </div>
                      </div>
                      <div>
                        <b>{t('inclusion')}</b>
                      </div>
                      <div className="row">
                        <div className="col-md-1">
                          <label>{t('blackInclusion')}</label>
                        </div>
                        <div className="col-md-5">
                          <table>
                            <tbody>
                              <tr id="BlackInclusion">
                                <label id="TBALL">
                                  <td>
                                    <input
                                      id="TBALL"
                                      type="checkbox"
                                      value="ALL"
                                      onChange={this.handleChage}
                                    />
                                    <p value="ALL">ALL</p>
                                  </td>
                                </label>
                                <label id="TBN">
                                  <td>
                                    <input
                                      id="TBN"
                                      type="checkbox"
                                      value="N"
                                      onChange={this.handleChage}
                                    />
                                    <p value="N">N</p>
                                  </td>
                                </label>
                                <label id="TBB0">
                                  <td>
                                    <input
                                      id="TBB0"
                                      type="checkbox"
                                      value="B0"
                                      onChange={this.handleChage}
                                    />
                                    <p value="B0">B0</p>
                                  </td>
                                </label>
                                <label id="TBB1">
                                  <td>
                                    <input
                                      id="TBB1"
                                      type="checkbox"
                                      value="B1"
                                      onChange={this.handleChage}
                                    />
                                    <p value="B1">B1</p>
                                  </td>
                                </label>
                                <label id="TBB2">
                                  <td>
                                    <input
                                      id="TBB2"
                                      type="checkbox"
                                      value="B2"
                                      onChange={this.handleChage}
                                    />
                                    <p value="B2">B2</p>
                                  </td>
                                </label>
                                <label id="TBB3">
                                  <td>
                                    <input
                                      id="TBB3"
                                      type="checkbox"
                                      value="B3"
                                      onChange={this.handleChage}
                                    />
                                    <p value="B3">B3</p>
                                  </td>
                                </label>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <div className="col-md-1">
                          <label>{t('shades')}</label>
                        </div>
                        <div className="col-md-5">
                          <table>
                            <tbody>
                              <tr id="SHADE">
                                <label id="NONESHADE">
                                  <td>
                                    <input
                                      id="NONESHADE"
                                      type="checkbox"
                                      value="NONE"
                                      onChange={this.handleChage}
                                    />
                                    <p value="NONE">{t('none')}</p>
                                  </td>
                                </label>
                                <label id="LIGHTBROWNSHADE">
                                  <td>
                                    <input
                                      id="LIGHTBROWNSHADE"
                                      type="checkbox"
                                      value="LIGHT BROWN"
                                      onChange={this.handleChage}
                                    />
                                    <p value="LIGHTBROWNSHADE">{t('lightBrown')}</p>
                                  </td>
                                </label>
                                <label id="MEDIUMBROWNSHADE">
                                  <td>
                                    <input
                                      id="MEDIUMBROWNSHADE"
                                      type="checkbox"
                                      value="MEDIUM BROWN"
                                      onChange={this.handleChage}
                                    />
                                    <p value="MEDIUMBROWNSHADE">{t('mediumBrown')}</p>
                                  </td>
                                </label>
                                <label id="BROWNSHADE">
                                  <td>
                                    <input
                                      id="BROWNSHADE"
                                      type="checkbox"
                                      value="BROWN"
                                      onChange={this.handleChage}
                                    />
                                    <p value="BROWNSHADE">{t('brown')}</p>
                                  </td>
                                </label>
                                <label id="GRAYSHADE">
                                  <td>
                                    <input
                                      id="GRAYSHADE"
                                      type="checkbox"
                                      value="GRAY"
                                      onChange={this.handleChage}
                                    />
                                    <p value="GRAYSHADE">{t('gray')}</p>
                                  </td>
                                </label>
                                <label id="GREENSHADE">
                                  <td>
                                    <input
                                      id="GREENSHADE"
                                      type="checkbox"
                                      value="GREEN"
                                      onChange={this.handleChage}
                                    />
                                    <p value="GREENSHADE">{t('green')}</p>
                                  </td>
                                </label>
                                <label id="MIXEDSHADE">
                                  <td>
                                    <input
                                      id="MIXEDSHADE"
                                      type="checkbox"
                                      value="MIXED"
                                      onChange={this.handleChage}
                                    />
                                    <p value="MIXEDSHADE">{t('mixed')}</p>
                                  </td>
                                </label>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-1">
                          <label>{t('eyeClean')}</label>
                        </div>
                        <div className="col-md-5">
                          <table>
                            <tbody>
                              <tr id="EC">
                                <label id="EYEALL">
                                  <td>
                                    <input
                                      id="EYEALL"
                                      type="checkbox"
                                      value="ALL"
                                      onChange={this.handleChage}
                                    />
                                    <p value="ALL">ALL</p>
                                  </td>
                                </label>
                                <label id="EYEEC">
                                  <td>
                                    <input
                                      id="EYEEC"
                                      type="checkbox"
                                      value="LIGHT"
                                      onChange={this.handleChage}
                                    />
                                    <p value="EC">EC</p>
                                  </td>
                                </label>
                                <label id="EYEE1">
                                  <td>
                                    <input
                                      id="EYEE1"
                                      type="checkbox"
                                      value="FAINT"
                                      onChange={this.handleChage}
                                    />
                                    <p value="E1">E1</p>
                                  </td>
                                </label>
                                <label id="EYEE2">
                                  <td>
                                    <input
                                      id="EYEE2"
                                      type="checkbox"
                                      value="MEDIUM"
                                      onChange={this.handleChage}
                                    />
                                    <p value="E2">E2</p>
                                  </td>
                                </label>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="col-md-1">
                          <label>{t('milky')}</label>
                        </div>
                        <div className="col-md-5">
                          <table>
                            <tbody>
                              <tr id="Milky">
                                <label id="MILKYNONE">
                                  <td>
                                    <input
                                      id="MILKYNONE"
                                      type="checkbox"
                                      value="NONE"
                                      onChange={this.handleChage}
                                    />
                                    <p value="MILKYNONE">{t('none')}</p>
                                  </td>
                                </label>
                                <label id="LIGHTMILKY">
                                  <td>
                                    <input
                                      id="LIGHTMILKY"
                                      type="checkbox"
                                      value="LIGHT MILKY"
                                      onChange={this.handleChage}
                                    />
                                    <p value="LIGHTMILKY">{t('lightMilky')}</p>
                                  </td>
                                </label>
                                <label id="MEDIUMMILKY">
                                  <td>
                                    <input
                                      id="MEDIUMMILKY"
                                      type="checkbox"
                                      value="MEDIUM MILKY"
                                      onChange={this.handleChage}
                                    />
                                    <p value="MEDIUMMILKY">{t('mediumMilky')}</p>
                                  </td>
                                </label>
                                <label id="MILKY">
                                  <td>
                                    <input
                                      id="MILKY"
                                      type="checkbox"
                                      value="MILKY"
                                      onChange={this.handleChage}
                                    />
                                    <p value="MILKY">{t('milky')}</p>
                                  </td>
                                </label>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="row"></div>
                      <div className="row">
                        <div className="col-md-1">
                          <label>Heart & Arrow</label>
                        </div>
                        <div className="col-md-5">
                          <table>
                            <tbody>
                              <tr id="HandA">
                                <label id="HEARTALL">
                                  <td>
                                    <input
                                      id="HEARTALL"
                                      type="checkbox"
                                      value="ALL"
                                      onChange={this.handleChage}
                                    />
                                    <p value="ALL">ALL</p>
                                  </td>
                                </label>
                                <label id="HEARTYES">
                                  <td>
                                    <input
                                      id="HEARTYES"
                                      type="checkbox"
                                      value="YES"
                                      onChange={this.handleChage}
                                    />
                                    <p value="YES">Yes</p>
                                  </td>
                                </label>
                                <label id="HEARTNO">
                                  <td>
                                    <input
                                      id="HEARTNO"
                                      type="checkbox"
                                      value="NO"
                                      onChange={this.handleChage}
                                    />
                                    <p value="NO">No</p>
                                  </td>
                                </label>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-md-5"></div>
                        <div className="col-md-4  mobilebutton">
                          <button
                            type="submit"
                            className="btn btn-info btn_search"
                            onClick={this.handleClick}
                          >
                            <i class="fas fa-search"></i>
                          </button>
                          <button
                            type="reset"
                            className="btn btn-info btn_reset"
                            onClick={this.resetData}
                          >
                            <i class="fas fa-sync-alt"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default SearchDiamond;
