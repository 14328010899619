import Axios from 'axios';
import disableBrowserBackButton from 'disable-browser-back-navigation';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import { I18nContext } from '../context/i18nContext';
import Footer from './Footer';
import Header from './Header';

class Home extends Component {
  // importing i18next context
  static contextType = I18nContext;

  constructor(props) {
    super(props);
    this.state = {
      UserName: localStorage.getItem('user'),
      token: localStorage.getItem('token'),
      wishlist: 0,
      cart: 0,
      inward: 0,
      accepted: '',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleSubmit(event) {
    event.preventDefault();

    Axios.post('/apiRequest', this.state, {
      headers: {
        Authorization: `Bearer ${this.state.token}`,
      },
    }).then((res) => {
      this.setState({
        accepted: res.data.msg,
      });
      if (res.data.msg === 'ACCEPTED') {
        swal('Your request has already been sent', '', 'warning');
      } else {
        swal('REQUEST SENT', '', 'success');
      }
    });
  }
  componentDidMount() {
    // console.log("home page token : "+localStorage.getItem('tokendevice'))
    if (this.state.token == null || this.state.UserName == null) {
      swal('Please Log In', '', 'error');
      this.props.history.push('/');
    } else {
      disableBrowserBackButton();
    }
    Axios.get(`/users/totalrecord/${this.state.UserName}`).then((res) => {
      this.setState({
        wishlist: res.data.count,
        cart: res.data.countcart,
        inward: res.data.inward,
      });
    });
    Axios.post('/updateDeviceToken', {
      Username: localStorage.getItem('user'),
      deviceToken: localStorage.getItem('tokendevice'),
    }).then((resp) => {});
  }
  render() {
    const { t } = this.context;

    return (
      <div className="pt-5">
        <Header />
        <div className="wrapper">
          <div className="content-wrapper">
            <div className="container-fluid">
              <div className="row pt-5 mt-5 justify-content-center">
                <div className="col-md-3">
                  <div
                    className="card"
                    style={{ background: 'rgba(0, 0, 0, .1)' }}
                  >
                    <Link to={window.innerWidth<=480 ? `/chooseDiamondType` : `/searchDiamond`}>
                      <div
                        className="card-body text-center"
                        style={{ color: 'black' }}
                      >
                        <div>
                          <i className="far fa-2x fa-gem"></i>
                        </div>
                        <div className="pt-4">
                          <span>{t('searchDiamonds')}</span>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-md-3">
                  <div
                    className="card"
                    style={{ background: 'rgba(0, 0, 0, .1)' }}
                  >
                    <Link to="/ViewCart">
                      <div
                        className="card-body text-center"
                        style={{ color: 'black' }}
                      >
                        <div>
                          <i className="fa-2x fa fa-shopping-cart"></i>
                        </div>
                        <div>
                          <b>{this.state.cart}</b>
                        </div>
                        <div>
                          <span>{t('cartList')}</span>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-md-3">
                  <div
                    className="card"
                    style={{ background: 'rgba(0, 0, 0, .1)' }}
                  >
                    <Link to="/ViewWishlist">
                      <div
                        className="card-body text-center"
                        style={{ color: 'black' }}
                      >
                        <div>
                          <i className="fa-2x fa fa-heart"></i>
                        </div>
                        <div>
                          <b>{this.state.wishlist}</b>
                        </div>
                        <div>
                          <span>{t('wishList')}</span>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-md-3">
                  <div
                    className="card"
                    style={{ background: 'rgba(0, 0, 0, .1)' }}
                  >
                    <Link to="/Demand">
                      <div
                        className="card-body text-center"
                        style={{ color: 'black' }}
                      >
                        <div>
                          <i className="fa-2x fas fa-user"></i>
                          <i className="fa-2x far fa-gem"></i>
                        </div>
                        <div>
                          <b>Offers</b>
                        </div>
                        <div>
                          <span>{t('yourReqOffer')}</span>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-md-3">
                  <div
                    className="card"
                    style={{ background: 'rgba(0, 0, 0, .1)' }}
                  >
                    <Link onClick={this.handleSubmit}>
                      <div
                        className="card-body text-center"
                        style={{ color: 'black' }}
                      >
                        <div>
                          <i class=" fa-2x fas fa-exchange-alt"></i>
                        </div>
                        <div>
                          <b>Stock List API</b>
                        </div>
                        <div>
                          <span>{t('apiAccAllow')}</span>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-md-3">
                  <div
                    className="card"
                    style={{ background: 'rgba(0, 0, 0, .1)' }}
                  >
                    <Link to="/changePassword">
                      <div
                        className="card-body text-center"
                        style={{ color: 'black' }}
                      >
                        <div>
                          <i class="fa-2x fas fa-key"></i>
                        </div>

                        <div className="pb-4">
                          <b>{t('changePassword')}</b>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default Home;
